@import 'styles/custom/includes';

.video-component {
    height: auto;
    border: $divider;
    color: $gray-87;

    @include breakpoint($md) {
        &--playlist {
            &.column-12 {
                display: flex;

                .video-container__wrapper {
                    min-width: 75%;
                    position: relative;
                }
                .playlist__wrapper {
                    min-width: 25%;
                    position: relative;

                    &.playlist-sm {
                        .playlist-header {
                            height: 100px;
                            box-sizing: border-box;
                            pointer-events: none;

                            .dropdown-caret {
                                display: none;
                            }
                        }
                    }
                }
            }
            &:not(.column-12) {
                .playlist__wrapper {
                    .playlist-body {
                        max-height: inherit;
                        overflow-y: auto;
                    }
                }
            }
        }
    }

    @include breakpoint($below-md) {
        &--playlist {
            .playlist__wrapper {
                .playlist-body {
                    max-height: inherit;
                    overflow-y: auto;
                }
            }
        }
    }

    .video-container {
        position: relative;
        min-height: 150px;
        line-height: 150px;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
        width: 100%;
        background: transparent;
        @include responsive-wrapper(16, 9, 'iframe');

        iframe {
            width: 100%;
            height: 100%;
        }

        img {
            display: inline-block;
            box-sizing: border-box;
            padding: 10px;
            position: absolute;
            top: 50%;
            margin-top: -56px;
            width: 126px;
            height: 112px;
            left: 50%;
            margin-left: -63px;
        }
    }

    .video-info {
        position: relative;
        padding: $gap;
        word-wrap: break-word;
        line-height: $line-height;
        margin-top: -4px;

        &:empty {
            display: none;
        }

        .channel,
        .title,
        .description {
            margin: 10px 0;
            line-height: $line-height;
        }

        .title{
            padding-top: 10px;
        }

        .channel {
            font-size: $abby;
            color: $gray-80;
            font-weight: $bold;
            display: block;
            width: 100%;
            line-height: 0.625;
            text-transform: uppercase;
        }

        .title {
            font-size: $ernie;
            font-weight: $bold;
        }

        .description {
            font-size: $ernie;
            font-weight: $light;
        }

        .additional-cove-info {
            margin: 10px 0;
            display: inline-block;
            font-size: $cloe;
            font-weight: $light;
            color: $gray-87;

            + .additional-cove-info {
                &:before {
                    content: '|';
                    padding: 0 3px;
                }
            }
        }
    }

    .playlist__wrapper {
        &.playlist-sm {
            position: relative;

            .playlist-body {
                overflow: auto;
                border: none;
            }

            .playlist-item {
                height: 100px;
                border-bottom: 1px solid $gray-0;

                .playlist-item-img-wrapper {
                    position: relative;

                    .playlist-item-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 70px;

                        &:not(.default) {
                            background-color: $gray-100;
                        }

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
        }

        &.playlist-lg {
            position: relative;

            .playlist-body {
                border: none;
            }

            .playlist-item {
                height: 100px;
                border-bottom: 1px solid $gray-0;

                .playlist-item-img-wrapper {
                    position: relative;

                    .playlist-item-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 90px;

                        &:not(.default) {
                            background-color: $gray-100;
                        }

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
        }
    }

    .playlist-header {
        font-size: $ernie;
        color: $gray-100;
        text-align: left;
        padding: 5px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        .playlist-title-container {
            display: flex;
            align-items: center;

            .playlist-title {
                flex: 1;
                flex-grow: 4;
                color: $gray-100;
                font-size: $oscar;
                word-wrap: break-word;
                min-width: 20px;
                position: relative;
                max-width: 100%;
            }
            .dropdown-caret {
                padding-top: 25px;
                flex: 1;
                flex-grow: 1;
            }
        }

        .playlist-items-count {
            display: block;
            font-size: $abby;
            padding: 3px;
        }
    }

    .playlist-body {
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 0;

        .playlist-item {
            text-align: left;
            padding: 5px;
            border-width: 0 0 1px 0;
            box-sizing: border-box;
            border-style: solid;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: left;
            width: 100%;

            &:hover,
            &:focus,
            &.selected-item {
                cursor: pointer;
            }

            .playlist-item-img-wrapper,
            .playlist-item-info {
                text-align: left;
                padding: 0 5px !important;
            }

            .playlist-item-info {
                .playlist-item-channel {
                    display: -webkit-box;
                    text-transform: uppercase;
                    font-size: $abby;
                    line-height: $abby;
                    font-weight: $bold;
                    color: $gray-71;
                    margin: 0;
                    padding: 0;
                    word-break: break-word;
                }
                .playlist-item-title {
                    display: -webkit-box;
                    font-size: $abby;
                    line-height: $abby;
                    font-weight: $normal;
                    color: $gray-87;
                    margin: 0;
                    margin-top: 7px;
                    padding: 0;
                    word-break: break-word;
                }
            }
        }
    }
}
