

.donate {

    padding: $gap $gap 0 $gap;

    article {
        display: flex;
        justify-content: center;
        flex-wrap: wrap-reverse;
    }

    .donate-editable {
        flex: 0 0 50%;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .donate-logo {
        margin-bottom: 20px;
        padding: 0 $gap;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;

        img {
            min-width: 160px;
        }
    }
    .donate-support {
        margin: 0 auto 20px auto;
        max-width: 410px;
        text-align: center;
        font-size: $ernie;
        line-height: 1.5;
        color: $night-blue;
        padding: 0 16px;
        word-break: break-word;
    }

    .donate-cta-link {
        width: 200px;
        max-width: 100%;
        height: 46px;
        background-color: #d6383a;
        margin-bottom: 20px;
        display: inline-block;
        padding: 8px;
        position: relative;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gray-0;
        font-size: $oscar;
        font-weight: $bold;
        text-align: center;

        &:hover,
        &:focus {
            background-color: #af2320;
        }

        &.has-pill-button {
            border: 2px solid #d6383a;
            border-radius: $pill-button-border-radius;
            font-weight: 600;
            font-size: 17px;
            height: 40px;
            line-height: 0;
            padding: 5px;
        }
    }

    .donate-pbs-brand {
        position: relative;
        display: inline-block;
        display: flex;
        flex: 0 0 50%;
        flex-grow: 1;
        justify-content: center;
        max-width: 100%;
        padding-top: $gap-large;

        img {
            max-width: 100%;
        }
    }
}
