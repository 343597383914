@import 'includes';

.gray-button {
    background: $blue-antique-darken-30 !important;
    display: inline-block;
    color: $gray-0;
    margin-right: 5px;
    padding: 8px;
    font-size: 14px;
    font-weight: $light;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    border: 1px solid $gray-0;
    min-width: 37px;
    min-height: 37px;
    text-align: center;
    cursor: pointer;
    text-shadow: none;
    transition: background-color $duration, border-color $duration, color $duration;

    &:hover,
    &:focus {
        background-color: $gray-0 !important;
        border-color: $blue-antique-darken-30 !important;
        color: $blue-antique-darken-30 !important;
        * {
            color: $blue-antique-darken-30 !important;
        }
    }

    i {
        margin-right: 0;
    }
}

.grippy {
    width: 12px;
    height: 30px;
    display: inline-block;
    overflow: hidden;
    line-height: 5px;
    cursor: move;
    vertical-align: middle;
    font-size: $ernie;
    font-family: sans-serif;
    letter-spacing: 2px;
    color: #cccccc;
    text-shadow: 1px 0 1px $gray-100;
    position: absolute;
    left: 3px;
    top: 22px;
    z-index: 1;

    &:after {
        content: '.. .. .. ..';
    }
}

.grip-vertical {
    padding: 6px 0px;
    cursor: move;
    span {
        line-height: 21px;
        color: white;
        text-shadow: none;
        position: relative;
        transform: rotate(90deg);
        display: table-cell;
        left: 2px;
        &::before {
            content: '::::';
        }
    }
}

.help-button {
    &,
    &:hover,
    &:focus {
        font-size: 17px;
        color: $gray-56;
        margin: 5px 0 0 4px;
        cursor: help;
        background: transparent;
        box-shadow: none;
        border: none;
    }
}
