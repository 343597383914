@import 'styles/custom/includes';

.page-header-component {
    height: 100px;
    padding: 12px 15px;
    position: relative;
    box-sizing: border-box;
    display: table-cell;

    @include breakpoint($below-md) {
        padding: 10px 15px;
        height: 75px;

        .page-header-items {
            .page-header-program-logo {
                max-height: 55px;
            }
        }
    }

    @include breakpoint($below-sm) {
        height: 100%;

        .page-header-items {
            .page-header-program-logo {
                max-height: 40px;
            }
        }
        .page-header-item:only-child {
            &.page-header-social {
                .social-container {
                    width: 100%;
                }
            }
        }
    }

    .page-header-items {
        position: relative;
        display: table;
        table-layout: fixed;
        width: 100%;
        height: 100%;

        .page-header-item {
            padding: 0 5px;
            max-width: 100%;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        .page-header-item:nth-of-type(1){
            text-align: left;
        }

        .page-header-item:nth-of-type(2){
            text-align: center;

            &.page-title-value {
                .text-input {
                    text-align: center;
                }
            }
        }

        .page-header-item:last-of-type{
            text-align: right;

            &.page-title-value {
                .text-input {
                     text-align: right;
                }
            }
        }

        .page-header-item:only-child {
            text-align: center;
        }
        .page-header-item:only-child {
            &.page-header-social {
                text-align: right;
            }

            &.page-title-value {
                .text-input {
                     text-align: center;
                }
            }
        }
        .page-title-value {
            .text-input {
                font-size: $benji;
                line-height: 1.1;
                background-color: transparent !important;
                font-weight: $semibold;
                word-wrap: break-word;

                @include breakpoint($below-md) {
                    font-size: $snuffy;
                }
                @include breakpoint($below-sm) {
                    font-size: $benji;
                }
            }
        }
        .page-header-logo {
            .page-header-program-logo {
                max-height: 76px;
                max-width: 100%;
            }
        }
        .page-header-social {

            .social-container {
                display: inline-block;

                .social-text {
                    text-align: center;
                    font-size: $elmo;
                    margin-bottom: 3px;

                    &.light {
                        color: $gray-0;
                    }

                    &.dark {
                        color: $gray-100;
                    }

                    &.color {
                        color: $gray-56;
                    }
                }
                .social-icons {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    .icon {
                        width: 35px;
                        height: 35px;
                        margin-right: 5px;
                        margin-bottom: 5px;
                        display: flex;
                        justify-content: center;

                        &:last-child {
                            margin-right: 0px;
                        }

                        &.border-light {
                            border: 1px solid $gray-0;
                        }

                        &.border-dark {
                            border: 1px solid $gray-100;
                        }

                        &.border-color {
                            border: 1px solid $gray-30;
                        }
                    }
                    a.icon {
                        &.border-light,
                        &.border-dark,
                        &.border-color {
                            border-radius: 2px;

                            &:hover,
                            &:focus {
                                background-color: rgba($gray-12, 0.5);
                            }
                        }
                    }
                    @each $icon-name in dropbox, facebook, instagram, linked-in, pinterest, rss, slack,
                                        spotify, tumblr, twitter, vimeo, you-tube, flickr, tiktok {
                        @each $theme in dark, light, color {
                            .#{$icon-name}-#{$theme} {
                                background: url('../../app/styles/images/custom-components/social/#{$theme}/#{$icon-name}.svg');
                                background-color: transparent;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center center;
                                width: 23px;
                                height: 23px;
                                margin: 6px;
                                align-self: center;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-header-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .page-header-background-image {
            background-size: cover;
            background-position: center center;
            height: 100%;
            width: 100%;
        }
    }
}

@include breakpoint($below-sm) {
    .page-header-component {
        display: block;

        .page-header-items {
            display: block;
            table-layout: initial;

            .page-header-item {
                padding: 6px;
                display: block;

                &:nth-of-type(1) {
                    text-align: center;
                }
                &:last-of-type {
                    text-align: center;

                    &.page-title-value {
                        .text-input {
                             text-align: center;
                        }
                    }
                }
            }
        }
    }
}

header {
    .page-header-component {
        &.hide-in-global {
            display: none;
        }
    }
}
