@import 'styles/custom/includes';
@import 'bento-components/poster-grid/poster.scss';

@mixin gridComputations($itemsCount) {
    .poster {
        $width: calc(90% / #{$itemsCount});
        width: $width;
        height: 0;
        margin-right: calc(5% / #{$itemsCount});
        margin-left: calc(5% / #{$itemsCount});

        &:before {
            content: '';
            display: block;
            margin-top: 100%;
        }
    }
    .poster-grid-title,
    .poster-grid-description {
        margin-left: calc(5% / #{$itemsCount});
    }
}

.poster-grid {
    list-style: none;
    position: relative;

    &.column-12 {
        @media (min-width: 992px) {
            @include gridComputations(6);
        }
        @media (min-width: 768px) and (max-width: 991px) {
            @include gridComputations(4);
        }
        @media (max-width: 767px) {
            @include gridComputations(2);
        }
    }

    &.column-8 {
        @media (min-width: 992px) {
            @include gridComputations(4);
        }
        @media (min-width: 768px) and (max-width: 991px) {
            @include gridComputations(3);
        }
        @media (max-width: 767px) {
            @include gridComputations(2);
        }
    }

    .poster-grid-title {
        color: $gray-87;
        font-size: $benji;
        font-weight: normal;
        margin-bottom: 15px;
        word-wrap: break-word;
    }

    .poster-grid-description {
        color: $gray-87;
        font-size: $zoe;
        font-weight: normal;
        margin-bottom: 15px;
        word-wrap: break-word;
    }

    .button-wrapper {
        text-align: center;
    }

    .poster-grid-btn-loadMore {
        @include cta-btn(false);

        &.has-pill-button {
            @include pill-buttons('pill-button-container');
        }
    }

    .poster-grid-pages {
        text-align: center;
        margin-top: 10px;

        ul {
            box-shadow: none;
            border: none;
            border-radius: 0;
        }

        li {
            display: inline-block;

            * {
                border: none;
                font-weight: $normal;
                font-size: $zoe;
                background-color: transparent;
            }

            &.active {
                .current {
                    background-color: transparent;
                }
            }
            a {
                padding: 4px 6px;

                &:hover,
                &:focus {
                    cursor: pointer;
                    background-color: transparent;
                }
            }
            span {
                padding: 4px 6px;
            }
            .next {
                border-right: none !important;
            }
        }
    }

    .posters {
        position: relative;
        overflow: visible;

        & > .poster {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;
            position: relative;

            &:hover, &:focus {
                cursor: pointer;
            }

            &.loadMore,
            &.pagination {
                display: none;
            }

            .grid-item {
                position: relative;
                height: 100%;
                width: 100%;

                .grid-image-container {
                    height: 100%;
                    width: 100%;
                    position: relative;

                    .grid-image {
                        max-height: 100%;
                        max-width: 100%;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

    }
    .pager-wrapper {
         border-top: $divider;
         text-align: center;
         cursor: pointer;
         padding: $gap 0;
         font-size: $ernie;
         color: $gray-56;

        .poster-grid-pager {
            display: inline-block;
            list-style: none;

            li {
                display: inline-block;
                text-align: center;

                &, a {
                    color: inherit;
                }

                &.active {
                    * {
                        color: $gray-56;
                        text-decoration: underline;
                    }
                }

                &.previous {
                    margin-right: $control-btn-spacing;
                }

                &.next {
                    margin-left: $control-btn-spacing;
                }

                &:not(.previous):not(.next),
                .fa-angle-left,
                .fa-angle-right {
                    display: inline-block;
                    min-width: 15px;
                    margin: 0 5px;

                }
            }
        }
    }
}

.webui-popover.webui-popover-poster-grid {
    border-radius: 2px;
    box-shadow: none;
    z-index: 1;
    border: solid 1px $gray-30;

    .webui-arrow {
        top: 50% !important;
    }

    @include breakpoint($below-sm) {
        max-width: 100%;
    }

    .webui-popover-inner .close:after {
        font-size: 1.5em;
    }

    .webui-popover-content {
        padding: $gap;
    }

    .poster-details-title {
        @extend .poster-details-title;
    }

    .poster-details-description {
        @extend .poster-details-description;
    }

    .poster-details-cta {
        @include cta-btn(false);
        margin-top: $gap-half;

        &.has-pill-button {
            @include pill-buttons('pill-button-container');
        }
    }
}
