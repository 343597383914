@import 'styles/custom/includes';

.social-component {
    padding: 10px 5px;
    display: flex;

    .social-text {
        font-size: $elmo;
        margin-bottom: 3px;

        &.align-left {
            text-align: left;
        }
        &.align-center {
            text-align: center;
        }
        &.align-right {
            text-align: right;
        }
        &.light {
            color: $gray-0;
        }

        &.dark {
            color: $gray-100;
        }

        &.color {
            color: $gray-56;
        }
    }
    .social-icons {
        display: flex;
        flex-wrap: wrap;

        .icon {
            width: 35px;
            height: 35px;
            margin: 0 5px 5px 0;
            display: flex;
            justify-content: center;

            &:last-child {
                margin: 0 0 5px 0;
            }
            &.border-light {
                border: 1px solid $gray-0;
            }
            &.border-dark {
                border: 1px solid $gray-100;
            }
            &.border-color {
                border: 1px solid $gray-30;
            }
        }
        a.icon {
            &.border-light,
            &.border-dark,
            &.border-color {
                border-radius: 2px;

                &:hover,
                &:focus {
                    background-color: rgba($gray-12, 0.5);
                }
            }
        }
        @each $icon-name in dropbox, facebook, instagram, linked-in, pinterest, rss, slack,
                            spotify, tumblr, twitter, vimeo, you-tube, flickr, tiktok {
            @each $theme in dark, light, color {
                .#{$icon-name}-#{$theme} {
                    background: url('../../app/styles/images/custom-components/social/#{$theme}/#{$icon-name}.svg');
                    background-color: transparent;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 23px;
                    height: 23px;
                    margin: 6px;
                    align-self: center;
                }
            }
        }
        &.align-left {
            justify-content: flex-start;

        }
        &.align-center {
            justify-content: center;
        }
        &.align-right {
            justify-content: flex-end;
            .icon {
                margin: 0 0 5px 5px;
                &:first-child {
                    margin: 0 0 5px 0;
                }
            }
        }
    }
}
