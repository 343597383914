
$lg-thumbnail-width: 120px;
$md-thumbnail-width: 100px;

.photo-gallery {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .gallery-image {
        user-select: none;
    }

    &.fullscreen {
        width: 100%;
        height: 100%;
        justify-content: flex-end;

        .img-description,
        .thumbnails-container,
        .thumbnail-list { //override theme styles
            background: $gray-100 !important;
            color: $gray-0 !important;
        }

        .thumbnail-list.centered {
            justify-content: center;
        }

        .img-title {
            display: none;
        }

        .image-container {
            max-height: 100%;
            flex-grow: 2;
            background: $gray-0;
        }
    }

    .img-title {
        padding: 0 0 $gap-half $gap-quarter;
        font-size: $snuffy;
        line-height: $snuffy;
        font-weight: $bold;
        margin: 0;
        word-break: break-word;
    }

    .image-container {
        position: relative;
        height: 300px;

        @media(min-width: $sm) {
            height: $sm * 9 / 16;
        }

        @media(min-width: $md) {
            height: $md * 9 / 16;
        }

        @media(min-width: $lg) {
            height: $md * 9 / 16;
        }

        img {
            width: auto;
            height: 100%;
            object-fit: cover;
        }
    }


    .gallery-image {
        display: none;
        text-align: center;

        &.visible {
            display: block;

            .fullscreen & {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-grow: 10;
            }
        }
    }

    .arrow {
        position: absolute;
        background: rgba(0, 0, 0, .3);
        color: #fff;
        font-size: 30px;
        padding: $gap-half;
        margin-bottom: (-1) * $lg-thumbnail-width * 9 / 16;

        @media(max-width: $md) {
            margin-top: (-1) * $md-thumbnail-width * 9 / 16
        }


        &-right {
            right: 0;
        }

        &-left {
            left: 0;
        }

        &:hover,
        &:focus {
            cursor: pointer;
        }

        @media(min-width: $md) {
            font-size: 40px;
            padding: 20px;
        }
    }

    .img-credit-box {
        background: rgba(0, 0, 0, .3);
        padding: $gap-half;
        min-width: 150px;
        max-width: 50%;
        min-height: 44px;
        position: absolute;
        bottom: 0;
        left: 0;

        .img-credit {
            color: $gray-0;
            font-size: $abby;
            font-style: italic;
            font-weight: $normal;
            margin: 0;
            text-align: left;
            word-break: break-word;

            @media(min-width: $md) {
                font-size: $ernie;
            }
        }
    }

    .image-controls {
        display: flex;
        justify-content: flex-end;
        width: 136px;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .social-share-icons {
        display: none;
        position: absolute;
        background: rgba(0, 0, 0, .3);
        color: $gray-0;
        font-size: $oscar;
        width: 136px;
        text-align: center;
        top: -67px;
        right: -44px;

        .social-share-row {
            margin: 5px 0;
        }

        a {
            color: $gray-0 !important;
        }

        i {
            padding: 0 10px;

            &:hover,
            &:focus {
                color: $gray-12;
            }
        }
    }

    .social-share-cta {
        display: none;
        position: absolute;
        background: rgba(0, 0, 0, .3);
        color: $gray-0;
        font-size: $abby;
        width: 136px;
        text-align: center;
        padding: 5px 15px;
        top: -31px;
        right: -44px;
    }

    .social-share-container {
        position: relative;
        width: 100%;

        &:hover,
        &:focus {
            cursor: pointer;

            .social-share-cta {
                display: block;
            }

            .social-share-button {
                background: rgba(0, 0, 0, .3);
            }
        }

        &.visible {
            display: block;

            .social-share-cta,
            .social-share-icons {
                display: block;
            }

            .social-share-button {
                background: rgba(0, 0, 0, .3);
            }
        }
    }

    .fullscreen-button,
    .social-share-button,
    .image-count {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gray-0;
        height: 44px;
        min-width: 44px;
        padding: $gap-quarter;
        background: rgba(0, 0, 0, .3);

        i {
            font-size: $snuffy;
            margin: 0;
        }
    }

    .social-share-button,
    .image-count {
        margin-right: 2px;
    }

    .fullscreen-button {
        &:hover,
        &:focus {
            cursor: pointer;
            background: rgba(0, 0, 0, .3);
        }
    }

    .img-description {
        text-align: left;
        padding: $gap-half;
        width: 100%;
        color: $gray-87;
        font-size: $elmo;
        font-weight: $normal;
        line-height: $line-height;
        margin: 0;
        word-break: break-word;

        @media(min-width: $md) {
            font-size: $ernie;
        }
    }

    .thumbnails-container {
        display: inline-block;
        overflow-x: hidden;
        overflow-y: hidden;
        position: relative;
        white-space: nowrap;

        // change to dark color scheme when in fullscreen mode
        .fullscreen & {
            background: $gray-71;
        }
    }

    .thumbnail-list {
        display: inline-flex;
        position: relative;
        padding: $gap-half $gap-half $gap-half $gap-half;
        overflow-x: auto;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 7px;
        }
    }

    .gallery-image.visible {
        position: relative;

        .thumb-arrow {
            position: absolute;
            background: rgba(0, 0, 0, .5);
            color: #fff;
            z-index: 1;
            top: calc(100% + 8px);
            font-size: 24px;
            padding: 5px $gap-half;

            &-right {
                right: 0;
            }

            &:hover,
            &:focus {
                cursor: pointer;
            }

            &.hidden {
                display: none;
            }
        }
    }

    .thumbnail-item {
        width: $lg-thumbnail-width;
        height: $lg-thumbnail-width * 9 / 16;
        display: flex;
        justify-content: center;
        margin-right: 6px;

        &.selected-thumbnail {
            box-sizing: border-box;
        }

        @media(max-width: $md) {
            width: $md-thumbnail-width;
            height: $md-thumbnail-width * 9 / 16;
        }

        .thumbnail-image {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

