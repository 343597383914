.video-app-content,
.video-app-content-left div
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    background-color: $white;
}
.video-app-content-left{
    width: 700px;
}
.video-app-content-right{
    width: 337px;
}

.button.download-app {
    border-radius: 29px;
    background-color: $pbs-blue;
    font-size: $font-size-medium;
    font-weight: $bold;
    margin: 10px 0px;
    text-align: center;
    line-hright: 35px;
    color: $gray-0;
    padding: 5px 24px;
    border: solid 3px $pbs-blue;
    text-decoration: none;
    &:hover {
        background-color: $medium-blue;
        border: solid 3px $medium-blue;
    }
    &:active {
        border: solid 3px $light-blue;
        background-color: $medium-blue;
    }
}
img {
    max-height: 100%;
    max-width: 100%;
}
.video-app-component{
    margin-bottom: 50px;
}
.stream-text{
    color: $pbs-blue;
    font-size: 40px;
    font-family: 'PBSSans';
    width: 100%;
    text-align: center;
    padding: 10px 0px 0px 0px;
    line-height: 55px;
}

.video-app-content{
    border: 2px solid $azureish-white;
}
.video-app-img{
    max-width: 337px;
}
.pbs-content{
    display: flex;
}
.pbs-button{
     padding-right: 15px;
}
.pbs-icon{
     padding-left: 15px;
}
