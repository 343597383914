@import '../menu-mixins';
@import './mega-menu';

// $bp: the point at which mobile menus give way to desktop menus
$bp: $md + 1;
$below-bp: (
        max-width: $bp - 1
);

$header-height: 70px;
$search-icon-width: 60px;
$menu-shadow: inset -10px 0 10px -10px rgba(0, 0, 0, 0.25);

.station-header {
    line-height: $header-height;

    ul {
        @include clearfix();
        margin: 0;
        list-style-type: none;
    }

    .menu-container {
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: $max-width;

        @include breakpoint($bp) {
            position: relative;
        }
    }

    &.has-search {
        @include breakpoint($below-bp) {
            .menu-container {
                .menu__ul {
                    padding-bottom: 70px;
                }
            }
        }
    }

    &.menu--navbar-nav {
        @include menu-collapsible();
        position: relative;
        left: 0;

        &.navbar-fixed-top {
            position: fixed;
            top: 0;
            z-index: 3;
        }

        @include breakpoint($below-bp) {
            z-index: 1;
        }
    }

    .menu__ul.navbar-nav {
        @include breakpoint($below-bp) {
            display: flex;
            flex-direction: column;
            height: 100vh;
            border-right: 0;
            box-shadow: $menu-shadow;

            .menu__li,
            .submenu__li {
                border-right: 1px solid $gray-12;
                border-bottom: 1px solid $gray-12;

                &.submenu-is-open {
                    .menu-item,
                    .mega-menu {
                        box-shadow: $menu-shadow;
                        max-width: 100%;
                    }
                }
            }
        }

        @include breakpoint($bp) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-right: $search-icon-width;
            width: auto;
            max-width: 100%;
            background-color: $gray-0;
            top: auto;

            .menu__li {
                position: relative;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;

                &:hover {
                    border-right: 1px solid $gray-12;
                    border-left: 1px solid $gray-12;

                    .submenu {
                        border: 1px solid $gray-12;
                        border-top: 0;
                    }
                }
            }

            .menu__li {
                &,
                .menu-item {
                    line-height: $header-height;
                }
            }
        }

        .menu__li {
            > .menu-item {
                &,
                &:hover,
                &:focus {
                    display: block;
                    color: $gray-87;
                }
            }

            &.highlight {
                .menu-item {
                    color: $red;
                    font-weight: $semibold;
                }
            }

            @include breakpoint($bp) {
                height: $header-height;

                > .menu-item {
                    line-height: $header-height;
                    display: block;
                    padding: 0 20px;
                    font-size: $ernie;

                    .fas {
                        display: none;
                    }

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }

            @include breakpoint($below-bp) {
                > .menu-item {
                    font-size: $zoe !important;
                    padding: $gap $gap-double $gap $gap-three-quarts;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .submenu {
            border: none;

            .submenu__li {
                border: none;
                display: block;
                line-height: 0;

                > .menu-item {
                    @include breakpoint($bp) {
                        padding: 12px 17px 12px 22px;
                    }
                    padding: 19px 35px 19px 35px;
                    line-height: initial;
                    font-size: $elmo;
                    text-transform: none;
                    font-weight: normal;
                    display: block;
                    width: 100%;
                    box-sizing: border-box;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }

            &.mega-menu {
                @extend .mega-menu-layout;
            }
        }
    }

    .station-header-logo {
        display: inline-block;
        width: auto;
        box-sizing: content-box;
        float: right;
        margin-right: $gap;
        position: relative;

        @include breakpoint($bp) {
            float: left;
            margin: 0;
        }
        @include breakpoint($below-bp) {
            margin-right: 0;
        }
        @media (max-width: $lg - 1) {
            padding: 0 10px;
        }
        @media (min-width: $lg) {
            padding: 0 $gap;
        }

        img {
            max-height: $header-height;
            max-width: 120px;
            max-height: 67px;
        }
    }

    .station-header-search {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $mobile-menu-ratio;
        max-width: $mobile-menu-width;
        height: $header-height;
        box-sizing: content-box;

        @include breakpoint($bp) {
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
        }

        @include breakpoint($below-bp) {
            order: -1;
            width: 100%;
        }

        &:hover {
            cursor: pointer;

            @include breakpoint($bp) {
                border-left: 1px solid $gray-12;
                border-right: 1px solid $gray-12;
            }
        }

        .dropdown-menu {
            display: block;
            box-shadow: none;
            border-radius: 0;
            z-index: 9;
            top: 0;
            left: 0;
            padding: 0;
            width: 100%;
            max-width: $mobile-menu-width;
            height: $header-height;
            border: 1px solid $gray-12;
            border-top: 0;
            margin: 0;

            @include breakpoint($below-bp) {
                background-color: $gray-0 !important;
                box-sizing: border-box;
            }

            .station-header-search-form {
                .visually-hidden {
                    display: none;
                }

                #station-header-search-input {
                    border: none;
                    background-color: transparent;
                    width: auto;
                }
            }

            .station-header-search-form {
                margin-bottom: 0;

                position: relative;
                padding: 13px 55px 12px 6px;

                .fas {
                    position: absolute;
                    cursor: pointer;
                    height: 45px;
                    line-height: 45px;
                    font-size: 15px;
                    width: 45px;
                    text-align: center;
                    right: 0;
                    border: none;
                    background: transparent;
                    font-size: $ernie;
                    display: inline-block;

                    &:hover {
                        font-weight: $semibold;
                    }
                }

                #station-header-search-input {
                    width: 100%;
                    padding-right: 50px;
                    height: 45px;
                    box-sizing: border-box;
                    border-radius: 0;
                    border-color: $gray-30;
                    margin-bottom: 0;
                    font-size: $elmo;
                    box-shadow: none;
                    outline: none;
                }
            }
        }

        @include breakpoint($bp) {
            box-sizing: content-box;
            color: $gray-87;
            width: $search-icon-width;

            .dropdown-menu {
                display: none;
                top: 100%;
                left: auto;
                right: -1px;
                width: 350px;
                height: auto;
                background: none;

                .station-header-search-form {
                    padding: 20px 10px 20px 10px;

                    .visually-hidden {
                        display: none;
                    }

                    #station-header-search-input {
                        background-color: $gray-0;
                        border: 1px solid $gray-5;
                    }
                }
            }

            &.open,
            &:hover,
            &:focus {
                .dropdown-menu {
                    display: block;
                }
            }
            &:not(.open):not(:focus):not(:hover) {
                .dropdown-menu {
                    display: none;
                }
            }
        }

        .menu-search-icon {
            font-size: 18px;
            position: relative;
            text-align: center;

            @media (max-width: $lg - 1) {
                padding: 0 10px;
            }
            @media (min-width: $lg) {
                padding: 0 $gap;
            }
        }
    }

    .live-link-text {
        text-decoration: none;
        padding: 1.5px 2px;
        line-height: 24px;
        font-size: 14px;
        font-weight: 700;
        border: 2px solid #2638c4;
        color: #2638c4;
    }

    a.station-header-login {
        @include breakpoint($below-bp) {
            padding: 0 10px;
            margin: 0;
        }

        padding: 0 20px;
    }

    .station-header-login {
        @include breakpoint($below-bp) {
            order: -1;
            padding: 0;
            margin: 0;
            line-height: 56px;
            border-right: none !important;
            border-left: none !important;

            #menu-dropdown {
                padding: 0 2.5px;
            }
        }

        @include breakpoint($bp) {
            #menu-dropdown {
                padding: 0 20px;
            }

            &:hover {
                background: #2638c4;
                color: $gray-0;

                .caret {
                    border-top-color: $gray-0 !important;
                }
            }
        }

        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        text-decoration: none;
        font-weight: 500;
        color: #2638c4;
        line-height: $header-height;
        display: block;
        font-size: 1.6rem;
        right: 0px;
        cursor: pointer;
        text-decoration: none;

        #menu-dropdown {
            strong {
                margin: 8px;
            }

            .caret {
                background: none;
                display: inline-block;
                border-width: 6px;
                border-top-color: #2638c4;
                vertical-align: middle;
            }
        }

        .menu-dropdown {
            :hover {
                background: none;

                strong {
                    background: none;
                }
            }

            cursor: pointer;
            background: #2638c4;
            color: $gray-0;
            text-decoration: none;

            .caret {
                border-top: 0 !important;
                border-bottom: 6px solid $gray-0;
            }
        }

        ul {
            z-index: 1000;
            list-style-type: none;
            list-style-position: outside;
            top: 100%;
            background: #2638c4 !important;
            padding: 0;
            margin: 0;
            right: 0px;

            li {
                &:hover {
                    background: #0e5891;
                }

                cursor: pointer;
                text-align: center;

                a {
                    display: block;
                    text-decoration: none;
                    color: $gray-0;
                    line-height: 4;
                    min-width: 120px;
                    min-width: 18em;
                }
            }
        }
    }
}
