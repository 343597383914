.google-ad-manager{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    .support-info-container {
        display: flex;
        justify-content: space-between;

        p.support-text {
            font-size: $elmo;
        }
        a.cta-text {
            font-family: PBSSans;
            font-size: $elmo;
            font-weight: $bold;
        }
    }
}

