.links-component {
    padding: 3px;

    .links-wrapper {
        display: inline-block;
        width: 100%;

        .links-section {
            margin-left: 0;

            .links-list {

                .list-element-container {
                    margin-top: 5px;

                    &, a {
                        line-height: $benji;
                        font-size: $zoe;
                        font-weight: $normal;
                    }



                }
            }
        }

        .links-title {
            margin-bottom: 5px;

            &, a {
                line-height: $zoe;
                font-size: $zoe;
                font-weight: $semibold;
                margin-bottom: 5px;
                text-transform: uppercase;
            }
            & {
                color: $gray-87;
            }
            a {
                &:hover,
                &:focus {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

        }
    }
}



