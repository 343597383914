.carousel {
    background-color: $gray-0;
    margin-bottom: 0;

    @mixin imageSizeDesktop($height) {
        height: $height;
        width: 100%;

        > .image-container-link,
        > .image-container {
            height: calc(#{$height} * 2);
            width: 60%;
            margin-top: calc((-1 * #{$height}) / 2);
            margin-left: 0;

            img {
                width: 100%;
                height: $height;
            }
        }
    }

    @mixin imageSizeMobile() {
        height: auto;
        width: 100%;

        > .image-container {
            width: 200%;
            height: 50vw;
            margin-left: calc(-1 * 50%);
            margin-top: 0;

            img {
                width: 50%;
                height: 100%;
            }
        }

        > .image-container-link {
            width: 200%;
            height: 50vw;
            margin-left: calc(-1 * 50%);
            margin-top: 0;

            .image-container {
                width: 50%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &.circle-crop-carousel,
    &.full-size-carousel {
        .details-container {
            .description,
            .title,
            .cta {
                color: $gray-100;
            }

            .description {
                padding: $gap-half $gap;
                margin-top: $gap-half;
                font-weight: $normal;
                font-size: $ernie;
                line-height: 1.38;
            }
            .title {
                padding: $gap-half $gap;
                font-size: $ida;
                font-weight: $bold;
                line-height: 1.3333;
            }
            .cta {
                padding: $gap-half $gap;
                margin-top: $gap-half;
                font-size: $ernie;
                font-weight: $bold;
                margin-bottom: 0;
            }

            a {
                &.cta,
                &.title {
                    cursor: pointer;
                }
            }

            @media (max-width: $md) {
                .title {
                    font-size: $charlie;
                }
                .description,
                .cta {
                    font-size: $elmo;
                }
            }
        }

        .logo-container,
        .title {
            @media (max-width: $md) {
                &.desktopOnly {
                    display: none;
                }
            }

            @media (min-width: $md + 1) {
                &.mobileOnly {
                    display: none;
                }
            }
        }
    }

    &.circle-crop-carousel {
        position: relative;
        overflow: hidden;

        .slides-wrapper {
            width: 100%;
            height: 100%;
        }

        .circle-crop-slide {
            position: relative;

            > .image-container-link,
            > .image-container {
                background: $gray-0;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;

                @media (max-width: $md) {
                    border-bottom-left-radius: 50%;
                    border-bottom-right-radius: 50%;
                    border-top-left-radius: 0%;
                }

                img {
                    object-fit: cover;
                }
            }

            @media (min-width: $md + 1) {
                @include imageSizeDesktop(442px);
                display: flex;
                justify-content: space-between;
            }

            @media (max-width: $md) {
                @include imageSizeMobile();
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
            }

            .details-container {
                background-color: transparent;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                overflow: hidden;

                @media (min-width: $md + 1) {
                    padding-left: 50px;
                    padding-right: $gap-quarter;
                    width: calc(40% - 50px);
                }
                @media (max-width: $md) {
                    padding: 0;
                    width: 100%;
                }

                .logo-container {
                    padding: $gap-half $gap;
                    height: 100px;
                    box-sizing: content-box;
                    width: auto;

                    @media (max-width: $md) {
                        height: 60px;
                    }

                    img {
                        width: auto;
                        height: 100%;
                    }
                }
            }

            .animation-stop,
            .animation-start {
                i {
                    border-radius: 50%;
                    box-shadow: 5px 4px 15px 3px rgba(0, 0, 0, 0.5);
                }
            }
        }

        .carousel-indicators {
            width: 60%;
            left: 40%;

            @media (max-width: $md) {
                top: calc(50vw - 40px);
                left: auto;
                width: 100%;
            }
        }
    }

    &.full-size-carousel {
        position: relative;
        overflow: hidden;

        .slides-wrapper {
            width: 100%;
            height: 100%;
        }

        .full-size-slide {
            position: relative;
            display: flex;
            justify-content: space-between;
            height: 442px;
            width: 100%;

            @media (max-width: $md) {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                height: auto;
            }
            .image-background {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: $gray-0;
                opacity: 0.8;
                z-index: 0;

                .blurry-image {
                    filter: blur(18px);
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                @media (max-width: $md) {
                    display: none;
                }
            }
            > .image-container-link,
            > .image-container {
                height: 100%;
                z-index: 0;
                position: relative;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                }
                &.full-width-image {
                    display: flex;
                    width: 100%;
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    display: inline-block;
                }

                @media (min-width: $md + 1) {
                    width: 60%;
                    height: 100%;

                    &.full-width-image {
                        &:after {
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(
                                    242deg,
                                    rgba(0, 0, 0, 0) 56%,
                                    rgba(0, 0, 0, 0.77)
                                ),
                                linear-gradient(
                                    180deg,
                                    rgba(0, 0, 0, 0) 85%,
                                    rgba(0, 0, 0, 0.2),
                                    rgba(0, 0, 0, 0.5)
                                );
                        }
                    }
                    &:not(.full-width-image) {
                        &:after {
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(
                                180deg,
                                rgba(0, 0, 0, 0) 85%,
                                rgba(0, 0, 0, 0.2),
                                rgba(0, 0, 0, 0.5)
                            );
                        }
                    }
                }
                @media (max-width: $md) {
                    width: 100%;
                    height: 50vw;

                    &:after {
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 50vw;
                        background: linear-gradient(
                            180deg,
                            rgba(0, 0, 0, 0) 85%,
                            rgba(0, 0, 0, 0.2),
                            rgba(0, 0, 0, 0.5)
                        );
                    }
                }
            }
            .details-container {
                background-color: transparent;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                overflow: hidden;

                @media (min-width: $md + 1) {
                    margin-left: 50px;
                    margin-right: $gap-quarter;
                    width: 40%;

                    &.full-width-image {
                        position: absolute;
                        z-index: 1;

                        &.dark-details {
                            .description {
                                color: $gray-100;
                            }
                        }
                        &.light-details {
                            .title,
                            .description,
                            .cta {
                                color: $gray-0;
                            }
                        }
                    }

                    &:not(.full-width-image) {
                        z-index: 0;

                        &.light-bg {
                            .description {
                                color: $gray-100;
                            }
                        }
                        &.dark-bg {
                            .description,
                            .title,
                            .cta {
                                color: $gray-0;
                            }
                        }
                        &.blur-bg {
                            &.dark-details {
                                .description {
                                    color: $gray-100;
                                }
                            }
                            &.light-details {
                                .title,
                                .description,
                                .cta {
                                    color: $gray-0;
                                }
                            }
                        }
                    }
                }
                @media (max-width: $md) {
                    width: 100%;
                    padding: 0;
                    position: relative;

                    &.light-bg,
                    &.blur-bg {
                        background-color: $gray-4;

                        .description {
                            color: $gray-100;
                        }
                    }
                    &.dark-bg {
                        .title,
                        .description,
                        .cta {
                            color: $gray-0;
                        }
                    }
                }

                .logo-container {
                    padding: $gap-half $gap;
                    height: 100px;
                    box-sizing: content-box;
                    width: auto;

                    @media (max-width: $md) {
                        height: 60px;
                    }

                    img {
                        width: auto;
                        height: 100%;
                    }
                }
            }
            .animation-stop,
            .animation-start {
                i {
                    border-radius: 50%;
                    box-shadow: 5px 4px 15px 3px rgba(0, 0, 0, 0.5);
                }
            }
        }

        .carousel-indicators {
            width: 100%;

            @media (max-width: $md) {
                top: calc(50vw - 40px);
            }
        }
    }

    &.regular-carousel {
        position: relative;

        .regular-slide {
            position: relative;
            height: 100%;

            > .image-container-link,
            > .image-container {
                position: relative;
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 442px;

                @media (max-width: $md) {
                    height: 50vw;
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0) 85%,
                        rgba(0, 0, 0, 0.2),
                        rgba(0, 0, 0, 0.5)
                    );
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .details-container {
                background-color: $gray-100;
                padding: $gap;

                .description {
                    margin: $gap-half 0;
                    font-weight: $normal;
                    font-size: $abby;
                    color: $gray-0;
                }
                .title {
                    margin: $gap-half 0;
                    font-size: $snuffy;
                    font-weight: $bold;
                    color: $gray-0;
                }
            }
        }

        .carousel-indicators {
            width: 100%;
            text-align: center;

            @media (max-width: $md) {
                top: calc(50vw - 40px);
            }
        }
    }
    .arrows {
        position: absolute;
        width: 100%;
        height: 0;
        top: calc(442px / 2 - 20px);

        @media (max-width: $md) {
            display: none;
        }

        .arrow-background {
            position: absolute;
            width: 40px;
            height: 40px;
            background-color: rgba(0, 63, 84, 0.3);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.arrow-right {
                right: $gap-half;
            }
            &.arrow-left {
                left: $gap-half;
            }
            .fa-angle-right,
            .fa-angle-left {
                opacity: 1;
                color: $gray-0;
                font-size: $socorro;
            }
        }
    }

    .carousel-indicators {
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        top: calc(442px - 40px);
        list-style: none;
        z-index: 0;

        li {
            display: inline-block;
        }

        .indicator {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin: 3px;
            background-color: rgba(255, 255, 255, 0.4);
            border-radius: 50%;
            box-shadow: 2px 3px 3px $gray-71;

            &.active {
                background-color: $gray-0;
            }

            @media (max-width: $md) {
                width: 12px;
                height: 12px;
                margin: 7px;
            }
        }
    }
    button {
        //overriding default <button> styling for arrows and indicators
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
    }

    .image-background, //overriding default <figure> styling
    .image-container,
    .logo-container {
        margin-inline-start: 0;
        margin-inline-end: 0;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    .slide {
        > .image-container-link,
        > .image-container {
            @media (max-width: $md) {
                position: relative;
            }
        }

        &.regular-slide,
        &.full-size-slide {
            .image-container-link {
                .image-container {
                    height: 100%;
                }
            }
        }
        .image-container-link {
            .image-container {
                width: 100%;
            }
        }
    }
    @media (min-width: $md + 1) {
        .circle-crop-carousel {
            .carousel-indicators {
                left: 70%;
            }
        }
        .full-size-carousel,
        .regular-carousel {
            .carousel-indicators {
                left: 50%;
            }
        }
    }
    .slide {
        &:not(.current) {
            display: none;
        }
    }
    .animation-stop,
    .animation-start {
        position: absolute;
        right: $gap;
        bottom: $gap;
        top: auto;

        i {
            background-color: $gray-100;
            color: $gray-0;
            font-size: $frankie;
            border-radius: 50%;
        }
    }
    &:not(.animating) {
        .animation-stop {
            display: none;
        }
    }
    &.animating {
        .animation-start {
            display: none;
        }
    }

    @media (max-width: $md) {
        &.circle-crop-carousel,
        &.full-size-carousel {
            .animation-stop,
            .animation-start {
                bottom: auto;
                top: $gap;
                right: $gap;
            }
        }
    }
    //handling for large displays where the carousel is full-width (header or content full width layout)
    @media (min-width: $lg + 1) {
        &.full-width {
            .circle-crop-slide {
                @include imageSizeDesktop(35vw);
            }
            .full-size-slide {
                height: 35vw;
            }
            .regular-slide {
                > .image-container-link,
                > .image-container {
                    height: 35vw;
                }
            }
            .arrows {
                top: calc(35vw / 2 - 20px);
            }
            .carousel-indicators {
                top: calc(35vw - 40px);
            }
        }
    }
}
