.menu-promo-component {
    padding: 15px;

    .menu-promo-wrapper {
        display: block;
        width: 100%;

        .menu-promo-title {

            &, a {
                line-height: $zoe;
                margin-bottom: 15px;
                font-size: $zoe;
                font-weight: $semibold;
                text-transform: uppercase;
                color: $gray-87;
            }
            a {
                &:hover {
                    cursor: pointer;

                }
            }
        }

        .menu-promo-img-container {

            &.default {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $gray-12;
                position: relative;

                img {
                    display: inline-block;
                    max-width: 40%;
                    padding-top: 80px;
                    padding-bottom: 80px;
                }
            }

            &:not(.default) {
                padding: 0;

                img {
                    max-width: 100%;
                    padding: 0;
                }
            }
        }

        .menu-promo-description {
            font-size: $zoe;
            font-weight: $normal;
            padding: 0px;
            line-height: $zoe;
        }

        .menu-promo-details-container {

            &, a {
                font-size: $zoe;
                margin-top: 20px;
                display: inline-block;
                position: relative;
            }

            a {
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
}
