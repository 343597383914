@import 'styles/custom/includes';

.sponsor-logos-component {
    position: relative;
    padding: $gap $gap $gap-quarter $gap;

    &.center {
        .sponsor-logos-title,
        .sponsor-logos-description,
        .sponsor-logos-support,
        .sponsor-logos-funding {
            text-align: center;
        }

        .logos {
            justify-content: center;
        }
    }

    &.right {
        .sponsor-logos-title,
        .sponsor-logos-description,
        .sponsor-logos-support,
        .sponsor-logos-funding {
            text-align: right;
        }

        .logos {
            justify-content: flex-end;
        }
    }

    .sponsor-logos-title {
        padding: 3px;
        margin-bottom: 25px;
        color: $gray-100;
        font-size: $benji;
        font-weight: $normal;
        font-style: italic;
        line-height: 1.1;
        word-break: break-word;
    }

    .sponsor-logos-description {
        padding: 3px;
        margin-bottom: 25px;
        color: $gray-87;
        font-size: $ernie;
        font-weight: $normal;
        word-break: break-word;
    }

    .sponsor-logos-funding {
        padding: 3px;
        margin-bottom: 25px;
        color: $gray-87;
        font-size: $elmo;
        font-weight: $normal;
        font-style: italic;
        word-break: break-word;
    }

    .sponsor-logos-support {
        padding: 3px;
        margin-bottom: 25px;
        color: $gray-100;
        font-size: $elmo;
        font-weight: $normal;
        word-wrap: break-word;
    }

    .logos {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-top: $gap;

        .logo-container {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: none;
            padding: 2px 8px;
            margin: 3px;
            width: 200px;
            height: 50px;
            box-sizing: content-box;

            .logo-img {
                max-width: 200px;
                max-height: 50px;
            }

            @include breakpoint($below-md) {
                .logo-container {
                    width: 160px;
                    height: 40px;

                     .logo-img {
                        max-width: 160px;
                        max-height: 40px;
                    }
                }
            }


        }
    }
}
