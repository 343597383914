.audio-player{
    border: solid 3px transparent;

    .content {
        padding: 18px 11px 5px 11px;
        display: flex;
        flex-wrap: wrap;
        .player {
            margin-bottom: 13px;
            flex-grow: 1;
            overflow: hidden;
        }
    }

    img {
        width: 100%;
        width: 82px;
        height: 82px;
        object-fit: cover;
        margin-right: 14px;
        margin-bottom: 13px;
    }

    .player-details {
        .audio-station {
            font-size: $abby;
            color: $night-blue;
            margin: 0;
            word-wrap: break-word;
        }
    
        .audio-title {
            font-size: $elmo;
            font-weight: $bold;
            margin-bottom: 8px;
            word-wrap: break-word;
        }
    }

    .player-controls {
        display: flex;

        i.far {
            font-size: $snuffy;
            &[class*='fa-volume-'] {
                font-size: $oscar;
                font-weight: $bold;
            }
        }

        .player-progress,
        .player-volume,
        .play-button { 
            display: flex;
            padding: 0;
            align-items: center;
        }

        .player-progress {
            width: 100%;
            .time-passed,
            .time-remaining {
                font-size: $michelle;
                color: $steel-three;
                display: inline-block;
                width: 50px;
                text-align: center;
            }
        }

        .player-volume,
        .play-button {
            background: transparent;
            border: none;
            padding: 0;
            margin: 0;
            &:disabled i {
                opacity: 0.5;
            }
        }
        .player-volume {
            width: 25px;
            height: 19px;
        }

        input[type="range"] {
            -webkit-appearance: none;
            border: none;
            height: 6px;
            border-radius: 20px;
            background-color: $very-light-pink;
            width: calc(100% - 100px);
        } 

        input::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 13px;
            height: 13px;
            border: none;
            border-radius: 13px;
            background: $brownish-grey-two;
        
        }

        input:disabled::-webkit-slider-thumb {
            background: #aaa;
        }
    }

    .player-volume-popup {
        width: 30px;
        height: 120px;
        background-color: $gray-0;
        border: 1px solid #ccc;
        position: absolute;
        input[type="range"] {
            -webkit-appearance: none;
            border: none;
            height: 6px;
            width: 100px;
            border-radius: 20px;
            background-color: $very-light-pink;
            margin: 0 2px 0 15px;
            transform: rotate(270deg) translate(-48px, -52px);
        }
        input::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 13px;
            height: 13px;
            border: none;
            border-radius: 13px;
            background: $brownish-grey-two;
        
        }
        input:disabled::-webkit-slider-thumb {
            background: #aaa;
        }
    }
}

