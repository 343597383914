@import 'styles/custom/includes';
@import 'styles/custom/_elements';

@mixin verticalAlignMiddle($child) {
    &:before {
        display: inline-block;
        height: 100%;
        content: '';
        vertical-align: middle;
    }

    #{$child} {
        vertical-align: middle;
    }
}


:local(.poster) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &.dragging {
        opacity: 0;
    }

    &.drag-preview {
        height: 200px;
        width: 200px;
        position: relative;
        opacity: 0.7;
    }

    &:hover,
    &:focus {
        .poster-toolbar {
            opacity: 1;
        }
    }


    .grid-item {
        position: relative;
        height: 100%;
        background-color: $gray-12;

        &.add-poster {
            @include verticalAlignMiddle(article);
        }

        .placeholder {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
        }

        .filer-image-container {
            &.link {
                width: 100%;
                height: 100%;

                .children-wrapper {
                    width: inherit;
                    height: inherit;
                }
            }

        }

        [class^='imageWidget'] {
            height: 100%;
            @include verticalAlignMiddle(img);
            display: inline-block;
            width: 100%;

            .grid-image {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .bentomatic-image-widget{
            display: inline-block;
            height: 100%;
            width: 100%;
            position: static;
        }
    }



    .poster-toolbar {
        opacity: 0;
        bottom: 0;
        right: 0;
        position: absolute;

        .btn {
            @extend .gray-button;
            z-index: 1;
            margin-right: 0 !important;
        }
    }

    .action-toolbar,
    .poster-toolbar {
        &, & * {
            line-height: 0;
        }
    }
}

:local(.popover) {
    .Popover-tip {
        fill: $gray-0;
        stroke: $gray-30;
        stroke-dasharray: 29;
        stroke-width:1px;
        stroke-linejoin: round;
        z-index: 1;
        margin-right: -1px;
        margin-top: -1px;
        margin-left: -1px;
        margin-bottom: -1px;
    }

    .details-wrapper {
        display: block;
        text-align: left;
        overflow: visible;
        background-color: $gray-0;
        border: solid 1px $gray-30;
        border-radius: 2px;
        width: 400px;

        .details-inner {
            padding: 17px;
            height: auto;
            overflow: visible;


            .poster-details-title {
                padding: 0 0 10px;

                .ck-content p {
                    font-weight: $semibold;
                    font-size: $oscar;
                    color: $gray-56;
                    word-wrap: break-word;
                    margin-bottom: 5px;
                }
            }

            .poster-details-description {
                padding: 0 0 10px;

                .ck_content p {
                    font-weight: $normal;
                    font-size: $elmo;
                    color: $gray-87;
                    word-wrap: break-word;
                    margin: 0;
                }
            }

            ul {
                margin-left: -15px !important;
                width: fit-content;

                .list-element-container {
                    text-align: left;

                    .list-item-toolbar,
                    .link-settings {
                        &.popover {
                            top: -15px !important;
                        }
                    }

                    .button-item-text {
                        @include cta-btn(false);
                        position: relative;

                        &.pill-button-component {
                            @include pill-buttons('pill-button-container');
                        }

                        .has-link {

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    .link-grippy {
                         margin-top: $gap-larger;
                    }
                }

                .add-link {
                    button {
                        border-style: dashed;
                        border-width: 1px;
                        font-size: 1.6rem;
                        line-height: 1.4;
                        padding: 16px;
                        margin-top: 8px;
                    }
                }
            }
        }

    }
}


