@import 'styles/custom/includes';
// $bp: the point at which mobile menus give way to desktop menus
$bp: $md + 1;
$below-bp: (
    max-width: $bp - 1
);

@mixin mobile-menu() {
    @media (max-width: $bp - 1) {
        transition: all $duration ease-in;
    }

    .nav-is-open & {
        transform: translateX($mobile-menu-ratio);
        @include breakpoint($mobile-menu-cap) {
            transform: translateX($mobile-menu-width);
        }
        @include breakpoint($bp) {
            transform: translateX(0);
        }
    }
}

@mixin menu-collapsible {
    @include mobile-menu();
    width: 100%;
    border-bottom: 1px solid;

    .menu__trigger {
        width: 45px;
        height: 45px;
        padding: 0;
        margin: 10px;
        border: 1px solid;
        font-size: 2rem;
        position: relative;
        z-index: 1;

        @include breakpoint($bp) {
            display: none;
        }
    }
    &.menu--filled {
        .menu__trigger {
            background: $gray-100;
        }
    }
    &.menu--default {
        .menu__trigger {
            background: $gray-0;
        }
    }
    .pbs-hamburger {
        width: 35px;
        height: 35px;

        path {
            width: 100%;
            transform: rotate(0deg);
            transform-origin: 50% 50%;
        }

        .nav-is-open & {
            .path1 {
                // we need hard coded px values for FF
                display: none;
            }
            .path4 {
                display: none;
            }

            .path2 {
                // we need to scale down slightly to keep the bars in the viewbox
                transform: rotate(45deg) scale(0.9);
            }

            .path3 {
                transform: rotate(-45deg) scale(0.9);
            }
        }
    }

    .navbar-nav {
        @include clearfix();
        position: absolute;
        top: 0;
        bottom: 0;
        left: -$mobile-menu-ratio;
        width: $mobile-menu-ratio;
        max-width: $mobile-menu-width;

        @include breakpoint($below-bp) {
            overflow: auto;
        }

        @include breakpoint($mobile-menu-cap) {
            left: -$mobile-menu-width;
        }

        @include breakpoint($bp) {
            position: static;
            width: 100%;
            height: auto;
            min-height: 0;
            border-right: 0;
            max-width: $max-width;
            margin: 0 auto;
        }
    }

    &__spacer {
        position: fixed;
        bottom: 0;
        left: -$mobile-menu-ratio;
        width: $mobile-menu-ratio;
        max-width: $mobile-menu-width;
        height: 100%;
        content: '';
        opacity: 0;
        z-index: -1;
        transition: left $duration ease-in;

        .nav-is-open & {
            @include breakpoint($bp) {
                display: none;
            }
        }

        @include breakpoint($bp) {
            display: none;
        }
    }

    .menu__li {
        position: relative;

        @include breakpoint($bp) {
            float: left;
            &:hover,
            &:focus {
                .submenu {
                    display: block;
                }
            }
        }
    }

    .submenu__trigger {
        position: absolute;
        top: 0;
        right: 0;
        width: 45px;
        height: 55px;
        border: 0;
        background: transparent;
        font-size: 2rem;

        i {
            transition: all ($duration * 0.66) ease-in;
        }

        @include breakpoint($bp) {
            display: none;
        }
    }

    .submenu {
        z-index: 101;
        max-height: 0;
        overflow: hidden;
        font-size: 15px;
        transition: all $duration ease-out;

        @include breakpoint($bp) {
            display: none;
            position: absolute;
            top: 100%;
            max-height: none;
            min-width: 200px;
            border: 1px solid;
            border-top-width: 0;
            font-size: 17px;
        }

        .submenu__li {
            > a {
                padding-left: $gap-triple;

                @include breakpoint($bp) {
                    padding-left: $gap-large;
                }
            }
        }
    }

    .submenu-is-open {
        .submenu {
            max-height: 100%;
        }

        .submenu__trigger i {
            transform: rotate(-180deg);
        }
    }

    .open-submenu {
        .submenu {
            display: block;
        }
    }

    .menu__li,
    .submenu__li {
        > a {
            padding: 17px $gap-large 13px $gap-large;

            @include breakpoint($bp) {
                padding: 10px $gap-large 6px $gap-large;

                // has the down arrow on the right, so we
                // are reducing the padding to balance the
                // optical space
                &.has-children {
                    padding-right: $gap;
                }
            }

            [class*='icon'] {
                display: none;

                @include breakpoint($bp) {
                    display: inline;
                }
            }
        }
    }
}

@mixin menu-non-collapsible {
    width: 100%;
    border: 1px solid;

    .navbar-nav {
        @include clearfix();
        position: static;
        width: 100%;
        height: auto;
        min-height: 0;
        max-width: $max-width;
        margin: 0 auto;
    }

    .menu__li {
        position: relative;
        float: left;
    }

    .submenu {
        z-index: 101;
        overflow: hidden;
        transition: all $duration ease-out;
        display: none;
        position: absolute;
        top: 100%;
        max-height: none;
        min-width: 200px;
        border: 1px solid;
        border-top-width: 0;
        font-size: 17px;

        .submenu__li {
            > a {
                padding-left: $gap-large;
            }
        }
    }

    .menu__li,
    .submenu__li {
        > a {
            padding: 10px $gap-large 6px $gap-large;

            // has the down arrow on the right, so we
            // are reducing the padding to balance the
            // optical space
            &.has-children {
                padding-right: $gap;
            }

            [class*='icon'] {
                display: inline;
            }
        }
    }

    .menu__trigger,
    .submenu__trigger,
    .menu__spacer {
        display: none;
    }
}
