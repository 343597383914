.simple-button-component {
    margin: 3px;

    &.left {
        .simple-button-link,
        .simple-button-wrapper {
            margin-left: 0;
            margin-right: auto;
        }
    }
    &.right {
        .simple-button-link,
        .simple-button-wrapper {
            margin-left: auto;
            margin-right: 0;
        }
    }

    &.center {
        .simple-button-link,
        .simple-button-wrapper {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.filled {
        .text-input {
            color: $gray-0;
        }
    }

    &.hasHoverEffect {
        .simple-button-wrapper {
            &:hover,
            &:focus,
            &:active {

                .text-input {
                    color: $gray-0;
                }
            }
        }
    }
    .simple-button-link {
        display: table;
        max-width: 100%;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
    .simple-button-wrapper {
        display: table;
        max-width: 100%;
        line-height: 1;
        text-align: center;
        transition: 0.3s;

        .text-input {
            transition: 0.3s;
            margin: 0;
            font-size: 1.6em;
            font-weight: $bold;
            padding: 1em 2em;
            border: 1px solid;
            border-radius: 2px;
            word-break: break-word;

            &:hover,
            &:focus {
                text-decoration: none;
                cursor: pointer;
            }
        }

        &:active {
            transform: scale(0.95, 0.95);
        }
    }
    &.large {
        .text-input {
            font-size: 2.4em;
        }
    }

    &.hasPillButton {
        height: auto;
        min-height: 35px !important;


        .simple-button-wrapper {
            height: fit-content;

            .text-input {
                padding:  0.5em 2em;
                border: 2px solid  $basic-darker-blue;
                border-radius: $pill-button-border-radius;
            }
        }
    }

}
