@import './menu-mixins';
@import 'styles/custom/includes';

main.content {

    &.content-with-rail {
        .container {
            width: 100%;
            max-width: 1280px;
            padding: 0;
        }
    }
    &:not(.content-with-rail) {
        > .row-fluid {
           > .container {
                width: 100%;
                padding: 0;

                .content-wrapper {
                    .container {
                        width: 100%;
                        padding: 0;

                         &:not(.full-width) {
                            max-width: 1280px;
                         }
                    }
                }
            }
        }
   }

    .page-header-component,
    .circle-crop-carousel,
    .full-size-carousel {
        margin-top: -$vertical-spacing;
    }
}

header,
footer {
    > .row-fluid {
        .container {
            width: 100%;
            padding: 0;
        }
    }
}

body > header {
    position: relative;
    .container {
        margin: 0;

        div[class^="component"] {
            > :not(nav):not(.menu--navbar-nav__spacer) {
                @include mobile-menu();
            }
        }
    }
}


main, footer {
    @include mobile-menu();

    .component {
        margin-top: $vertical-spacing;

        &:not(:last-of-type) {
            .section-header-component {
                margin-bottom: (-1) * $vertical-spacing * 3/4;
            }
        }
    }
}


@include breakpoint($below-md) {
    .content-wrapper,
    .rail-wrapper {
        width: 100%;
        float: none;
    }
}

@include breakpoint($above-md) {
    .rail-wrapper {
        width: calc(25% - #{$gap-three-quarts});
        &.pull-left { margin-right: #{$gap-three-quarts * 2}; }
        &.pull-right { margin-left: #{$gap-three-quarts * 2}; }

        .row-fluid {
            .container {
                max-width: 100%;
            }
        }
    }
    .content-wrapper {
        width: calc(75% - #{$gap-three-quarts});
    }
}

.content-wrapper {
    &.full-width {
        width: 100%;
        float: none;
    }
}

main, header, footer {
    .container {
        &.has-background-image {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
}

main {
    .container {
        &.full-height {
            min-height: 100vh;
        }
        @include breakpoint($above-sm){
            &.center-layout-content-vertically {
                display: flex;
                align-items: center;

            }
        }
    }
}
header a.skipnav {
    display: block;
    color: #fff;
    background: #222;
    text-decoration: none;
    padding: 5px 10px;
    position: fixed;
    left: -1000px;
    top: 0;
    font-size: 1.5em;
}
header a.skipnav:focus,
header a.skipnav:active {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    border: solid #777 2px;
}
