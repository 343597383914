@import '../menu-mixins';

// $bp: the point at which mobile menus give way to desktop menus
$bp: $md + 1;
$below-bp: (
    max-width: $bp - 1
);

body {
    &.nav-is-open {
        height: 100vh;
        overflow-x: hidden;

        @include breakpoint($bp) {
            overflow-x: auto;
        }

        header {
            position: fixed;
            z-index: 3;
            height: 100%;
            width: 100%;
            overflow: hidden;

            .menu-container {
                height: 100vh;

                @include breakpoint($bp) {
                    height: initial;
                }
            }
        }
    }
}

.menu {
    height: auto;
    width: 100%;
    font-size: 17px;
    font-weight: $normal;

    ul {
        @include clearfix();
        margin: 0;
        list-style-type: none;
    }

    .menu-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: $max-width;
        margin: 0 auto;
        @include breakpoint($bp) {
            position: relative;
        }
    }

    .menu-item {
        display: block;
        border-bottom: 4px solid transparent;
        transition: background-color $duration ease-in;

        &:hover,
        &:focus {
            cursor: pointer;
            text-decoration: none;
        }

        &:focus {
            outline: $focusstate;
        }
    }
}

//collapsible menu (only applies in the header)
header {
    .menu--navbar-nav {
        @include menu-collapsible();
        position: relative;
        border-bottom-width: 5px;
    }

    @include breakpoint($bp) {
        .menu__li {
            box-shadow: none;

            &:hover,
            &:focus {
                .submenu {
                    display: block;
                }
            }
        }
        .submenu__li {
            box-shadow: none;
        }
    }

    @include breakpoint($below-bp) {
        .menu {
            z-index: 1;
        }
        .menu-item-caret {
            &.fa-angle-down,
            &.fa-angle-up {
                display: none;
            }
        }
    }
}

//non-collapsible menu (only applies in the content and footer)
footer,
.content {
    .menu--navbar-nav {
        @include menu-non-collapsible();

        .menu-container {
            position: relative;
        }
    }
    .menu__li {
        &:hover,
        &:focus {
            .submenu {
                display: block;
            }
        }
        &.open-submenu {
            .submenu {
                display: block;
            }
        }
    }
}

header,
footer,
.content {
    // white bgs
    .menu--default {
        .navbar-nav {
            @include breakpoint($below-bp) {
                border-right: 1px solid;
            }
        }
        &,
        .navbar-nav,
        + .menu--navbar-nav__spacer {
            background-color: $gray-0;
            border-color: $gray-12;
        }

        .submenu {
            background-color: $gray-0;
        }

        .submenu__trigger {
            color: $gray-87;
        }

        .submenu,
        .menu__trigger {
            border-color: $gray-12;
        }

        .menu-item {
            color: $gray-87;

            &:hover,
            &:focus {
                color: $gray-0;
            }
        }
    }

    // filled bgs
    .menu--filled,
    .menu--themed {
        &,
        .navbar-nav,
        + .menu--navbar-nav__spacer,
        .submenu {
            background-color: $gray-87;
        }

        .submenu__trigger {
            color: $gray-0;
        }

        &,
        .submenu,
        .menu__trigger {
            border-color: $gray-12;
        }

        .menu-item {
            color: $gray-0;
        }
    }

    // vertical navs
    .menu--nav-stacked {
        &:hover {
            .submenu {
                display: none;
            }
        }

        .menu__li {
            border: 0;
            border-bottom: 1px solid $gray-12;
            width: 100%;

            &:last-of-type {
                border: 0;
            }

            &.open-submenu-on-click {
                .submenu {
                    display: block;
                }
            }
        }

        .submenu {
            display: none;

            position: relative;
            border: 0;
            border-bottom: 1px solid $gray-12;
            width: 100%;
        }

        a {
            padding: 10px $gap 6px $gap;
            i {
                float: right;
            }
        }

        .submenu__li a {
            padding-left: $gap-double;
        }
    }

    .menu__trigger {
        padding: 0 10px;
    }

    .menu {
        .menu-container .navbar-nav {
            @include breakpoint($bp) {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                &.align-left {
                    justify-content: flex-start;
                }
                &.align-center {
                    justify-content: center;
                }
                &.align-right {
                    justify-content: flex-end;
                }
            }
        }
    }
}

.content {
    .menu--default,
    .menu--filled,
    .menu--themed {
        border-bottom: 1px solid $gray-12 !important;
    }
}
