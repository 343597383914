html {
    // needed for the menu to behave properly
    box-sizing: border-box;
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    font-size: 10px;

    &.modal-open {
        overflow: hidden;
    }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
    padding: 0;
    margin-top: 0 !important;
    font-size: 10px;
    font-weight: $normal;
    background-color: $gray-0;
    line-height: 1.5;
}

ul {
    list-style: none;
    margin: 0;
}

.container {
    .date {
        font-size: 1.3rem
    }

    .box {
        padding: $gap;
        word-wrap: break-word;
        background: $gray-0;

        &:first-child {
            margin-top: 0;
        }

        img {
          max-width: 100%
        }
    }
}

.clearfix {
    @include clearfix();
}

.hide-text {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.input-block-level {
    display: block;
    width: 100%;
    min-height: 31px;
    box-sizing: border-box;
}

img[src=""] {
    width: 0;
    height: 0;
    min-width: 0;
    min-height: 0;
}

table {
    td {
      padding: 3px;
      font-size: 1.3rem;
    }
}

pre,
code {
  font-size: 1.3rem;
}

// Hide only visually, but have it available for screenreaders: by Jon Neal. www.webaim.org/techniques/css/invisiblecontent/  &  j.mp/visuallyhidden
.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);

  // Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: drupal.org/node/897638
  &.focusable {
    &:active,
    &:focus {
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      clip: auto;
    }
  }
}

@include breakpoint($below-sm) {
    .hidden-xs{
        display: none !important;
    }
    .visible-xs{
        display: block !important;
    }
}
@include breakpoint($sm-only) {
    .hidden-sm{
        display: none !important;
    }
    .visible-sm{
        display: block !important;
    }
}

@include breakpoint($md-only) {
    .hidden-md{
        display: none !important;
    }
    .visible-md{
        display: block !important;
    }
}

@include breakpoint($above-lg) {
    .hidden-lg{
        display: none !important;
    }
    .visible-lg{
        display: block !important;
    }
}

.hidden-a11y {
    width: 1px;
    height: 1px;
    overflow: hidden;
    top: -10px;
    position: absolute;
}
