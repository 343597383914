// $bp: the point at which mobile menus give way to desktop menus
$bp: $md + 1;
$below-bp: (max-width: $bp - 1);

.mega-menu-layout {
    padding: 0;
    height: auto;
    max-width: 1280px;

    @include breakpoint($bp) {
        position: absolute;
        width: max-content;

        div[id^='layout-'] {
            div[id^='column-'] {
                border-right: 1px solid $gray-12;
            }
        }
    }

    div[id^='layout-'] {
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        width: auto;
        flex-direction: row;

        div[id^='column-'] {
            align-items: stretch;
            margin: 0;

            &:last-child {
                border-right: 0;
            }

            .component {
                padding: 5px 15px;
                white-space: initial;
                word-wrap: break-word;

                & > .text-container {
                    padding: 0;
                    background: transparent;
                }
            }
        }
    }

    @include breakpoint($below-bp) {
        top: 54px;
        width: 100%;

        div[id^='layout-'] {
            max-width: 100%;
            display: block;
            padding: 0;

            div[id^='column-'] {
                display: block;
                width: 100%;
                border-right: 0;

               .component {
                    padding: 15px;
                    white-space: initial;
                    word-wrap: break-word;

                    & * {
                        transform: none;
                    }

                    &>.text-container {
                        padding: 0;
                    }
                }
            }


        }

    }

}
