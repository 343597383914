.filer-image-container {
    position: relative;
    display: inline-block;
    margin: 0;
    overflow: hidden;

    .filer-image {
        height: auto;
        display: inline-block;
    }

    &.default-image {
        .filer-image {
            width: 100%;
            display: inline-block;
        }
    }

    .img-details {
        display: flex;
        width: 100%;
        background: rgba($gray-100, .6);
        border-top: 1px solid $gray-71;
        line-height: $line-height;
        letter-spacing: 1px;
        color: $white;
        padding: $gap;
        font-weight: $bold;
        min-width: 100px;
        word-break: break-word;

        .details-wrapper {
            flex-grow: 1;
            width: 0;
        }

        .img-caption {
            margin-bottom: $gap;
            font-size: $ernie;
        }

        .img-credit {
            font-size: $abby;
            font-style: italic;
            opacity: .9;
        }
    }
}
