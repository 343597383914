@import 'styles/custom/includes';
.text-container {
    @include clearfix();
    font-size: $ernie;
    font-weight: $normal;
    line-height: 1.625;
    margin: 0 auto;
    word-break: break-word;

    figure img + a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    hr {
        border-top: 1px solid #949494;
    }

    a {
        &,
        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }

        &:focus {
            outline: $focusstate;
        }
    }

    ul {
        list-style: initial;
        margin-left: $gap-large;

        li {
            line-height: 1.5;
        }
    }

    // this bit is here to accomodate old text components with
    // images that have not been modified since the adoption of ckEditor
    .text-component-img {
        margin-bottom: $gap;

        &[style*="left"] {
            margin: $gap-half $gap $gap-half 0;
        }

        &[style*="right"] {
            margin: $gap-half 0 $gap-half $gap;
        }
    }

    .image {
        position: relative;
        overflow: hidden;
        clear: both;
        text-align: center;
        margin: 1em 0;

        &.image-style-align-right {
            float: right;
            margin-left: $gap;
            max-width: 50%;

        }

        &.image-style-align-left {
            float: left;
            margin-right: $gap;
            max-width: 50%;

        }

        &.image-style-align-center {
            margin-left: auto;
            margin-right: auto;
            max-width: 50%;

            a {
                text-decoration: none;
            }
        }

        &.image-style-full-width {
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
            }
        }

        &:not(.image-style-full-size):not(.image-style-align-right):not(.image-style-align-center):not(.image-style-align-left) {
            display: inline-block;
        }

        figcaption {
            background: rgba($gray-100, .7);
            border-top: 1px solid $gray-71;
            padding: $gap;
            color: $gray-12;
            font-weight: $semibold;
            line-height: $line-height;
            letter-spacing: 1px;
            text-align: left;
            position: relative;
        }
    }
    figure.table {
        margin-inline-start: 0;
        margin-inline-end: 0;
        table {
            width: 100%;
        }
    }
}
