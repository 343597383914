@import 'styles/custom/includes';

.section-header-component {

    .section-header-value {
        margin: 0;
        font-weight: $semibold !important;
    }

    &.filled {
        .section-header-value {
            padding: $gap-half $gap;

            @include breakpoint($md){
                padding: $gap-half $gap-half;
            }
        }
    }

    &.underline {
        .section-header-value {
            padding: $gap-quarter 0;
            border-bottom-style: solid;
        }

        h1 {
            border-bottom-width: 5px;
            line-height: 1.7;
        }
        h2 {
            border-bottom-width: 4px;
            line-height: 1.6;
        }
        h3 {
            border-bottom-width: 2px;
            line-height: 1.5;
        }
    }
}
