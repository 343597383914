@import 'styles/custom/_elements';

$logo-expanded-max-width: 210px;

.expanded-footer {
    padding: $gap-triple;

    @include breakpoint($below-sm) {
        padding: $gap-large $gap;
    }

    .links-section {
        display: flex;
        flex-wrap: wrap;
    }

    .links-column,
    .flexible-footer-social {
        padding: 0;
        padding-right: 15px;
        width: 25%;

        @include breakpoint($below-md) {
            width: 33%;
        }
        @include breakpoint($below-sm) {
            width: 50%;
        }

        .column-header {
            position: relative;
            visibility: visible;
            &.hide-from-view {
                visibility: hidden;
            }
            font-size: $ernie;
            font-weight: $bold;
            text-transform: uppercase;
            margin-bottom: 18px;
        }
    }

    .links-section {
        margin-bottom: -$gap-double;
        @include breakpoint($below-sm) {
            margin-bottom: 0;
        }
    }

    .links-column {
        ul {
            margin-bottom: $gap-triple;
            @include breakpoint($below-sm) {
                margin-bottom: $gap-double;
            }
        }

        .link-element {
            padding-left: 0;
            font-size: $ernie;
            margin-bottom: 12px;
        }
    }

    .flexible-footer-social {
        .social-icons {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            svg, a svg {
                width: 18px;
                height: 18px;
                margin: 0 15px 15px 0;
                max-height: 18px;
            }
            &:last-child {
                margin: 0;
            }
        }
    }

    .contact-section {
        @include breakpoint($below-sm) {
            display: flex;
        }
        .footer-logo-container,
        .footer-logo-container-default,
        .footer-details {
            @include breakpoint($below-sm) {
                width: 50%;
                margin-bottom: $gap-double;
            }
        }
    }

    .footer-logo-container,
    .footer-logo-container-default {
        width: $logo-expanded-max-width;
        height: 110px;
    }

    .footer-logo-container,
    .footer-logo-container-default {
        margin-bottom: $gap-double;
        padding-right: 15px;
        .flexible-footer-logo {
            max-width: 100%;
            max-height: 110px;
        }
    }

    .footer-details {
        max-width: $logo-expanded-max-width;
        margin-bottom: 20px;
        &, p {
            margin-bottom: 0;
            font-size: $elmo;
            word-wrap: break-word;
        }
        p:empty {
            margin-bottom: $elmo;
        }
    }
}



