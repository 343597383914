@import 'styles/custom/_elements';

.flexible-footer {
    .copyright-wrapper {
        min-height: 60px;
        padding: $gap-large $gap;
        ul.links-column {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            .link-element {
                display: flex;
                padding-left: 0;
                font-size: $elmo;
                margin-bottom: 0;
                text-align: center;
                &:after {
                    content: "|";
                    margin: 0 5px;
                }
                &:last-child:after {
                    content: '';
                    margin: 0;
                }
                &:first-child {
                    &:after {
                        @include breakpoint($below-md) {
                            content: '';
                        }
                    }
                    @include breakpoint($below-md) {
                        margin: 0 0 $gap 0;
                    }
                }
            }
        }
    }
    
    .footer-logo-container-default {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed $gray-56;
    }

    .footer-logo-container,
    .footer-logo-container-default {
        .flexible-footer-logo {
            position: relative;
        }
    }
}


