@import 'styles/custom/_elements';

$logo-compact-max-width: 88px;

.compact-footer {
    padding: $gap-larger;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .links ul{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: -15px;
        .link-element {
            font-weight: $bold;
            font-size: $ernie;
            margin: 0 20px 15px 0;
            @include breakpoint($below-xs) {
                text-align: center;
                width: 100%;
            }
        }
    }

    .footer-logo-container-default {
        width: $logo-compact-max-width;
        height: 46px;
    }

    .footer-logo-container,
    .footer-logo-container-default {
        margin: 0 20px 0 0;
        .flexible-footer-logo {
            max-width: $logo-compact-max-width;
            max-height: 46px;
        }
    }
}



