@import 'styles/custom/includes';

$popup-width-landscape: 432px;
$popup-height-landscape: 384px;

$popup-width-portrait: 384px;
$popup-height-portrait: 432px;

$popup-width-landscape-large: calc(#{$popup-width-landscape} + 1 / 2 * #{$popup-width-landscape});
$popup-height-landscape-large: calc(
    #{$popup-height-landscape} + 1 / 2 * #{$popup-height-landscape}
);

$popup-width-portrait-large: calc(#{$popup-width-portrait} + 1 / 2 * #{$popup-width-portrait});
$popup-height-portrait-large: calc(#{$popup-height-portrait} + 1 / 2 * #{$popup-height-portrait});

body {
    &.nav-is-open {
        .popup {
            @include breakpoint($below-bp) {
                display: none;
            }
        }
    }
}

@keyframes showPopup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.popup {
    display: none;
    opacity: 0;

    &.open {
        animation: showPopup 0.3s ease-in normal;
        opacity: 1;
        display: block;

        .popup-overlay {
            display: block;
        }
    }

    &.closed {
        opacity: 0;
        display: none;

        .popup-overlay {
            display: none;
        }
    }

    .popup-overlay {
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0.7;
        z-index: 100;
    }
    .popup-modal {
        max-height: 100% !important;
        padding: $gap-half;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10002; // JAWS's header z-index: 10001

        .close-popup {
            z-index: 1;
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;
            background: transparent;
            border: none;
            display: flex;
            align-items: center;

            .fa-times {
                font-size: $ernie;
            }
        }
        .simple-preset-content {
            height: 100%;
            display: flex;
            align-items: center;

            .popup-text {
                padding: $gap;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;

                .popup-title {
                    font-size: $snuffy;
                    font-weight: $bold;
                    margin-bottom: $gap;
                    text-align: center;
                    word-break: break-word;
                }
                .popup-description {
                    font-size: $elmo;
                    margin-bottom: $gap;
                    text-align: center;
                    word-break: break-word;
                }
                .popup-cta-link,
                .popup-cta {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 100%;
                    padding: $gap-half $gap;
                    position: relative;
                    color: $gray-0;
                    font-size: $ernie;
                    text-align: center;
                    word-break: break-word;

                    &.has-pill-button {
                        @include pill-buttons('pill-button-container');
                    }
                }
            }
            .popup-image {
                text-align: center;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .popup-image-link {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                &:focus {
                    border: 1px solid $gray-0;
                }
            }
        }
    }

    &.dark {
        .popup-title,
        .popup-description {
            color: $gray-0;
        }
        .close-popup {
            .fa-times {
                color: $gray-0;
            }

            &:hover,
            &:focus {
                .fa-times {
                    color: $gray-12;
                }
            }
        }
        .popup-cta-link,
        .popup-cta {
            background-color: $gray-0;

            &.pill-button-container {
                @include pill-buttons-filled('pill-button-container-filled', 'dark-theme');
            }
        }
    }

    &.light {
        .popup-modal {
            background-color: $gray-0;

            .popup-cta-link,
            .popup-cta {
                color: $gray-0;

                &.pill-button-container {
                    @include pill-buttons-filled('pill-button-container-filled', 'light-theme');
                }
            }
        }
    }

    &.vertical {
        &.normal {
            .popup-modal {
                width: $popup-width-portrait;
                height: $popup-height-portrait;

                .popup-image,
                .popup-text {
                    height: calc(#{$popup-height-portrait} / 2);
                }
            }
        }

        &.large {
            .popup-modal {
                width: $popup-width-portrait-large;
                height: $popup-height-portrait-large;

                .popup-image,
                .popup-text {
                    height: calc(#{$popup-height-portrait-large} / 2);
                }
            }
        }

        .simple-preset-content {
            flex-direction: column;

            .popup-image,
            .popup-text {
                width: 100%;
            }
            .popup-image,
            .popup-image-link {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: $gap-half;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    &.verticalNoImage {
        &.normal {
            .popup-modal {
                width: $popup-width-portrait;
                max-height: $popup-height-portrait;
            }
        }

        &.large {
            .popup-modal {
                width: $popup-width-portrait-large;
                max-height: $popup-height-portrait-large;
            }
        }
        .popup-text {
            width: 100%;
            height: 100%;
            justify-content: center;
        }
    }
    &.horizontalLeftImage {
        &.normal {
            .popup-modal {
                width: $popup-width-landscape;
                height: $popup-height-landscape;

                .popup-image,
                .popup-text {
                    width: calc(#{$popup-width-landscape} / 2);
                }
            }
        }

        &.large {
            .popup-modal {
                width: $popup-width-landscape-large;
                height: $popup-height-landscape-large;

                .popup-image,
                .popup-text {
                    width: calc(#{$popup-width-landscape-large} / 2);
                }
            }
        }

        .simple-preset-content {
            flex-direction: row;

            .popup-image,
            .popup-text {
                height: 100%;
            }

            .popup-image {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                max-height: 100%;
            }
            .popup-text {
                height: 100%;
            }
        }
    }
    &.horizontalRightImage {
        &.normal {
            .popup-modal {
                width: $popup-width-landscape;
                height: $popup-height-landscape;

                .popup-image,
                .popup-text {
                    width: calc(#{$popup-width-landscape} / 2);
                }
            }
        }

        &.large {
            .popup-modal {
                width: $popup-width-landscape-large;
                height: $popup-height-landscape-large;

                .popup-image,
                .popup-text {
                    width: calc(#{$popup-width-landscape-large} / 2);
                }
            }
        }

        .simple-preset-content {
            flex-direction: row-reverse;

            .popup-image,
            .popup-text {
                height: 100%;
            }
            .popup-image {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                max-height: 100%;
            }
            .popup-text {
                height: 100%;
            }
        }
    }

    &.customLayout {
        div[id^='column'] {
            .component:first-of-type {
                margin-top: 0 !important;
            }
        }
        .container {
            width: 100%;

            &.has-background-image {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }
    }

    &.customLayout,
    &.customHTML {
        &.normal {
            .popup-modal {
                width: $popup-width-landscape;
                max-height: $popup-height-landscape;
            }
        }

        &.large {
            .popup-modal {
                width: $popup-width-landscape-large;
                max-height: $popup-height-landscape-large;
            }
        }
    }

    &.fixedTop {
        .popup-modal {
            top: 0;
            left: 0;
            bottom: auto;
        }
    }

    &.fixedBottom {
        .popup-modal {
            bottom: 0;
            left: 0;
            top: auto;
        }
    }

    &.fixedTop,
    &.fixedBottom {
        .popup-modal {
            display: flex;
            justify-content: center;
            position: fixed;
            width: 100%;
            min-height: 75px;
            transform: none;

            .fixed-preset {
                display: flex;
                align-items: center;
                flex-flow: wrap;
                padding: $gap-half $gap-double;

                .popup-description {
                    margin-right: $gap-large;
                    font-size: $elmo;
                    word-break: break-word;
                }
                .popup-cta-link,
                .popup-cta {
                    position: relative;
                    max-width: 100%;
                    min-width: fit-content;
                    padding: 6px 0;
                    color: $gray-0;
                    font-size: $ernie;
                    word-break: break-word;
                }
            }
        }
    }

    @media (max-width: calc(#{$popup-width-landscape} + 2 * #{$gap-large})) {
        &.horizontalRightImage,
        &.horizontalLeftImage { //make portrait because of small display
            &.normal {
                .popup-modal {
                    width: calc(100% - 2 *#{$gap-large});
                    height: $popup-height-portrait;

                    .simple-preset-content {
                        flex-direction: column;

                        .popup-image,
                        .popup-text {
                            width: 100%;
                            height: calc(#{$popup-height-portrait} / 2);
                        }

                        .popup-image {
                            padding-bottom: $gap-half;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .popup-image-link {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
        &.customHTML,
        &.customLayout,
        &.vertical,
        &.verticalNoImage {
            &.normal {
                .popup-modal {
                    width: calc(100% - 2 *#{$gap-large});
                }
            }
        }
        .close-popup {
            .fa-times {
                font-size: $benji;
            }
        }
    }

    @media (max-width: calc(#{$popup-width-landscape-large} + 2 * #{$gap-large})) {
        &.horizontalRightImage,
        &.horizontalLeftImage { //make portrait because of small display
            &.large {
                .popup-modal {
                    width: calc(100% - 2 *#{$gap-large});
                    height: $popup-height-portrait-large;

                    .simple-preset-content {
                        flex-direction: column;

                        .popup-image,
                        .popup-text {
                            width: 100%;
                            height: calc(#{$popup-height-portrait-large} / 2);
                        }

                        .popup-image {
                            padding-bottom: $gap-half;

                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .popup-image-link {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
        &.customHTML,
        &.customLayout,
        &.vertical,
        &.verticalNoImage {
            &.large {
                .popup-modal {
                    width: calc(100% - 2 *#{$gap-large});
                }
            }
        }
        .close-popup {
            .fa-times {
                font-size: $benji;
            }
        }
    }
}
