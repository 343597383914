$container-height: 180px;
$container-width: 160px;
$image-height: $container-height/2;

.blog-entry-container {
    display: inline-block;
    padding: 15px;
    width: 100%;

    .blog-entry-title {
        margin-bottom: 20px;
        display: inline-block;
        font-weight: $light;
        color: $gray-56;
        font-size: $nancy;
        line-height: 1.5;
    }

    .blog-entry-social {
        float: right;

        .blog-entry-social-icon {
            margin-right: -1px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            width: 35px;
            height: 35px;
            padding: 3px;
            box-sizing: border-box;
            background-color: $gray-0;
            border: 1px solid $gray-12;

            &:hover,
            &:focus {
                cursor: pointer;
                background-color: $gray-12;
            }

            img {
                display: inline-block;
                max-width: 90%;
                max-height: 90%;
            }
        }
    }

    .blog-entry-details {
        clear: both;
        display: block;

        .blog-entry-author {
            font-weight: $light;

            .blog-entry-author-name {
                display: inline-block;
                color: $gray-87;
                font-weight: $semibold;
                text-transform: capitalize;
            }
        }

        .blog-entry-date,
        .author-date-separator {
            color: $gray-56;
            font-weight: $light;
            font-size: $elmo;
        }

        .blog-entry-comment-cnt {
            font-weight: $light;
        }

        .blog-entry-author,
        .blog-entry-date,
        .blog-entry-comment-cnt {
            margin: 0 5px 10px 0;
            display: inline-block;
            font-size: $elmo;
        }
    }

    .blog-entry-image-wrapper {
        border: 1px solid #e0e0e0;
        margin-top: 10px;
        width: 100%;
        display: flex;

        figure {
            margin: 0 auto;
            text-align: center;
        }

        img {
            max-width: 100%;
        }

        .img-details {
            caption-side: bottom;
            width: 100%;
            background: rgba($gray-100, 0.7);
            border-top: 1px solid $gray-71;
            line-height: $line-height;
            letter-spacing: 1px;
            color: $gray-12;
            padding: $gap;
            font-weight: $bold;
            min-width: 100px;
            word-break: break-word;
            align-items: center;
            flex-direction: column;

            .img-caption {
                color: #d1d2d4;
                padding: 8px 0px;
                font-size: $zoe;
                line-height: $zoe;
                font-weight: $bold;
                word-wrap: break-word;
            }

            .img-credit {
                color: #d1d2d4;
                padding: 8px 0;
                font-size: $abby;
                line-height: $abby;
                font-style: italic;
                opacity: 0.7;
                font-weight: $bold;
                word-wrap: break-word;
            }
        }
    }

    .blog-entry-image-wrapper-default {
        @extend .blog-entry-image-wrapper;
        background-color: transparent;
        width: 100%;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .blog-entry-category-wrapper {
        display: block;
        position: relative;
        margin: $gap-half 0;
        text-align: center;
        text-decoration: none;

        .blog-entry-category {
            display: inline-block;
            border: solid 1px $gray-56;
            padding: 4px 14px;
            color: $brownish-gray;
            font-size: $zoe;
            text-transform: uppercase;
            max-width: 80%;
        }
    }

    .blog-entry-content {
        clear: both;
        margin-top: 20px;
        color: $gray-87;
        font-size: $ernie;
        clear: right;
    }

    .blog-entry-embedded {
        clear: both;
        margin-top: 20px;
    }

    .blog-entry-next-prev {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 30px 0;

        @include breakpoint($below-sm) {
            display: inline-block;
            transform: rotate(180deg);
            direction: rtl;
        }

        .entry-preview {
            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;
                text-decoration: none;
            }

            @include breakpoint($below-md) {
                .entry-preview-details {
                    width: 91.3% !important;
                }
            }

            @include breakpoint($below-sm) {
                transform: rotate(-180deg);
                direction: ltr;
                display: flex;
                border: 1px solid $gray-30;

                .entry-preview-author,
                .entry-preview-author-name,
                .entry-preview-date,
                .entry-preview-bull {
                    display: none;
                }

                &.next {
                    margin-top: 20px;

                    .entry-preview-details {
                        text-align: left;
                        padding: 5px;
                    }
                    .fa-angle-right {
                        padding: 5px;
                    }
                }

                &.previous {
                    .entry-preview-details {
                        text-align: right;
                        padding: 5px;
                    }
                    .fa-angle-left {
                        padding: 5px;
                    }
                }
                .entry-preview-title {
                    overflow-wrap: break-word;
                    white-space: initial;
                }
            }

            .entry-preview-label {
                text-transform: capitalize;
                font-size: $cloe;
                color: $gray-71;
                margin-bottom: 7px;
            }

            &.next {
                text-align: right;
            }

            .entry-preview-details {
                align-self: flex-start;
                max-width: 100%;

                @include breakpoint($above-md) {
                    margin-top: 27px;
                }
            }

            .entry-preview-img {
                height: 90px;
                width: 100%;
                background-color: $gray-5;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }

            .entry-preview-title {
                font-weight: $normal;
                font-size: $zoe;
                color: $gray-100;
                margin-top: 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .entry-preview-author,
            .entry-preview-author-name,
            .entry-preview-date,
            .entry-preview-bull {
                display: inline-block;
                margin-top: 5px;
                font-weight: $normal;
                font-size: $abby;
                color: $gray-87;
            }

            .entry-preview-bull {
                margin-right: 7px;
            }

            .entry-preview-author-name {
                text-transform: capitalize;
                font-weight: $semibold;
                color: $gray-100;
                margin-left: 5px;
                margin-right: 5px;
            }

            .entry-preview-categ-wrapper {
                display: inline-block;
                border: 1px solid $blue-digital;
                line-height: $cloe;
                max-width: 100%;

                .entry-preview-categ {
                    padding: 5px 8px;
                    color: $blue-digital;
                    font-weight: $semibold;
                    font-size: $abby;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-transform: uppercase;
                }
            }

            .fa-angle-right,
            .fa-angle-left {
                font-size: 30px;
                font-weight: $semibold;
                color: $gray-56;

                @include breakpoint($below-sm) {
                    font-size: 20px;
                }
            }
        }
    }
}

#disqus_thread {
    padding-top: 25px;
}
