$control-btn-spacing: 5px;
$image-width: 140px;

.rss-feed {
    .rss-error {
        font-size: $abby;
        padding: $gap;
        border: $divider;
        background: $gray-0;
    }

    .feed-description { 
        font-size: $ernie;
        color: $gray-56;
        margin-bottom: $gap-half;
    }
    
    .rss-articles-wrapper {
        padding: $gap;
        border: $divider;
        background: $gray-0;

        ul {
            margin: 0;
        }

        .articles {
            li {
                border-bottom: $divider;
                padding-bottom: $gap;

                &:last-child {
                    border-bottom: none;
                }

                .item-heading {
                    a {
                        color: inherit;
                    }
                }

                .item-image {
                    max-width: $image-width;
                    float: left;

                    & + .item-description,
                    & ~ .media-footer {
                        margin-left: $image-width + 15px;
                    }
                }

                .item-description {
                    font-size: $ernie;
                    color: $gray-56;
                    margin-bottom: $gap-half;
                }

                
            }
        }

        .media-footer {
            .item-pub-date {
                font-size: $cloe;
                color: $gray-56;
                float: left;
            }
            .read-more {
                font-size: $ernie;
                float: right;
            }
        }

        .pagination {
            list-style: none;
            cursor: pointer;
            text-align: center;
            padding: $gap 0;
            border-top: $divider;
            font-size: $ernie;
            color: $gray-56;

            li {
                display: inline-block;
                text-align: center;

                &, a {
                    color: inherit;
                }

                &.active {
                    a {
                        color: $gray-56;
                        text-decoration: underline;
                    }
                }

                &.previous {
                    margin-right: $control-btn-spacing;
                }

                &.next {
                    margin-left: $control-btn-spacing;
                }

                &:not(.previous):not(.next),
                .fa-angle-double-left,
                .fa-angle-double-right,
                .fa-angle-left,
                .fa-angle-right {
                    display: inline-block;
                    min-width: 25px;

                }
            }
        }
    }
}
