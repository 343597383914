$namespace: ".pbs-profile-grid";
$grid: ".og-grid";

@mixin gridItem($itemsCount) {
    width: calc(95% / #{$itemsCount});
    line-height: calc(95% / #{$itemsCount});
    margin-right: calc(5% / (#{$itemsCount} - 1));

    @include breakpoint($below-sm) {
        margin-bottom: 30px;
    }
    @include breakpoint($sm-only) {
        margin-bottom: 20px;
    }
    @include breakpoint($above-md) {
        margin-bottom: 17px;
    }


    position: relative;

    &:before {
        display: block;
        content: '';
        padding-top: 100%;

    }

    & > a {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        &:before {
            display: inline-block;
            content: '';
            vertical-align: middle;
            height: 100%;
        }

        img {
            vertical-align: middle;
        }
    }

    &:nth-child(#{$itemsCount}n) {
        margin-right: 0;
    }

}

@mixin detailsImage($size) {
    height: #{$size};
    width: #{$size};
    max-width: 100%;
    margin: auto;
    line-height: #{$size};
}

@mixin grid($g) {
    #{$g} {
        list-style: none;
        padding-top: 30px;
        margin: 0 auto;
        width: 100%;
        position: relative;
        font-size: 0;
        word-spacing: 0;
        letter-spacing: 0;

        li {
            display: inline-block;
            margin-right: 12px;
            vertical-align: top;
            text-align: center;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            &:nth-child(5n), &:last-child {
                margin-right: 0;
            }
            & > a,
            & > a img {
                border: none;
                outline: none;
                display: inline-block;
                position: relative;
                text-align: center;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
            & > a {
                display: inline-block;
                vertical-align: middle;

                &.hide-related-links {
                    .og-bottom-details {
                        display: none;
                    }
                }
            }
            h2 {
                position: absolute;
                bottom: 0px;
                left: 0px;
                background-color: rgba($gray-100, .6);
                width: 100%;
                padding: 10px;
                font-weight: normal;
                font-size: $snuffy;
                color: $gray-0;
                line-height: 21px;
                margin: 0;
                text-align: left;
                word-wrap: break-word;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
            }
            &.og-expanded:after {
                bottom: 0;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-width: 15px;
                left: 50%;
                margin: 0px 0 -11px -15px;
            }
        }
        .og-expander {
            position: absolute;
            top: auto;
            left: 0;
            width: 100%;
            margin-top: 10px;
            text-align: left;
            height: 0;
            overflow: hidden;
        }
        .og-expander-inner {
            padding: 30px;
            height: auto;
            overflow: hidden;

            &:after {
                display: block;
                content: '';
                clear: both;
            }

            @media (max-width: 767px) {
                padding: 30px;
            }
            @media (max-width: 320px) {
                padding-top: 50px;
            }
        }
        .og-close {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 20px;
            right: 20px;
            cursor: pointer;
            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                top: 50%;
                height: 1px;
                background: #888;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &::after {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            &:hover::before,
            &:hover::after,
            &:focus::before,
            &:focus::after {
                background: #333;
            }
            @media (max-width: 767px) {
                height: 30px;
                width: 30px;
                top: 15px;
                right: 15px;
                z-index: 10;
            }
        }
        .og-fullimg-wrapper,
        .og-details,
        .og-bottom-details {
            float: left;
            height: 100%;
            overflow: hidden;
            position: relative;

            &.og-details {
                float: none;
            }
        }
        .og-details,
        .og-bottom-details {
            height: auto;
            line-height: normal;
        }
        .og-fullimg-wrapper {
            text-align: center;
            height: auto;
            margin-right: 3%;
            border: 1px solid $gray-12;

            img {
                display: inline-block;
                max-height: 100%;
                max-width: 100%;
            }
            .og-img-credit {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: $gap;
                border-top: 1px solid $gray-71;
                background: rgba($gray-100, .5);
                font-size: $abby;
                font-style: italic;
                font-weight: $bold;
                line-height: $line-height;
                color: rgba($gray-12, .7);
                text-align: left;
                word-wrap: break-word;
            }
        }
        .og-details {
            h3 {
                font-weight: $light;
                font-size: 30px;
                line-height: 41px;
                padding: 0px 0 10px;
                margin-bottom: 10px;
                color: #464646;
                word-wrap: break-word;
            }
            p {
                font-weight: $normal;
                font-size: $ernie;
                line-height: 22px;
                color: $gray-87;
                margin-bottom: 0;
                word-wrap: break-word;
            }

            .og-details__cta--no-link {
                @include cta-btn(false);

                &.pill-button-component {
                    @include pill-buttons('pill-button-container');
                }
            }

            .og-details__cta--has-link {
                &.pill-button-component {
                    .og-details__link {
                        @include pill-buttons-link('pill-button-container');
                    }
                }
            }

            &__link {
                @include cta-btn(true);
            }
        }
        .og-bottom-details {
            border-top: 1px solid #ddd;
            margin-top: 30px;
            padding-top: 20px;
            font-size: $ernie;
            line-height: 22px;
            color: $gray-87;
            width: 100%;
            span {
                display: block;
                margin-bottom: 10px;
                word-break: break-word;
            }
            a {
                display: block;
                margin-bottom: 10px;
                padding-right: 20px;
                position: relative;
                text-decoration: none;
                word-wrap: break-word;
                width: 48%;
                height: auto;
                text-align: left;
                float: left;

                &:after {
                    content: "\f105";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 600;
                    font-style: normal;
                    text-decoration: inherit;
                    -webkit-font-smoothing: antialiased;
                    display: inline;
                    width: auto;
                    height: auto;
                    line-height: normal;
                    vertical-align: baseline;
                    background-image: none;
                    background-position: 0 0;
                    background-repeat: repeat;
                    margin-top: 0;
                    position: absolute;
                    font-size: 20px;
                    margin-left: 10px;
                }
            }
        }
        @media (max-width: 768px) {
            .og-bottom-details {
                width: 100%;
            }
            .og-fullimg-wrapper {
                height: auto;
                display: block;
            }
            .og-bottom-details a {
                width: 50%;
                float: left;
            }
        }
        @media (max-width: 767px) {
            text-align: center;

            .og-fullimg-wrapper,
            .og-details,
            .og-bottom-details,
            .og-bottom-details a {
                width: 100%;
                margin-right: 0
            }
            .og-details {
                margin-top: 20px;
            }
        }
        .og-loading {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $gray-12;
            box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ccc;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -25px 0 0 -25px;
            -webkit-animation: loader 0.5s infinite ease-in-out both;
            -moz-animation: loader 0.5s infinite ease-in-out both;
            animation: loader 0.5s infinite ease-in-out both;
        }
        .image-loading {
            -webkit-animation: fadeIn 0.65s ease forwards;
            -moz-animation: fadeIn 0.65s ease forwards;
            animation: fadeIn 0.65s ease forwards;
        }

        @-webkit-keyframes loader {
            0% {
                background: $gray-12;
            }
            33% {
                background: #ccc;
                box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px $gray-12;
            }
            66% {
                background: #ccc;
                box-shadow: 0 0 1px #ccc, 15px 30px 1px $gray-12, -15px 30px 1px #ccc;
            }
        }
        @-moz-keyframes loader {
            0% {
                background: $gray-12;
            }
            33% {
                background: #ccc;
                box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px $gray-12;
            }
            66% {
                background: #ccc;
                box-shadow: 0 0 1px #ccc, 15px 30px 1px $gray-12, -15px 30px 1px #ccc;
            }
        }
        @keyframes loader {
            0% {
                background: $gray-12;
            }
            33% {
                background: #ccc;
                box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px $gray-12;
            }
            66% {
                background: #ccc;
                box-shadow: 0 0 1px #ccc, 15px 30px 1px $gray-12, -15px 30px 1px #ccc;
            }
        }
    }
    .og-profiles-loading {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $gray-12;
        box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ccc;
        margin: 0 auto 60px;
        -webkit-animation: loader 0.5s infinite ease-in-out both;
        -moz-animation: loader 0.5s infinite ease-in-out both;
        animation: loader 0.5s infinite ease-in-out both;
    }
}
@mixin overides($g) {
    .column-12 #{$g} li {
        @media (min-width: 1401px) {
            @include gridItem(5);
        }
        @media (min-width: 1281px) and (max-width: 1400px) {
            @include gridItem(4);
        }
        @media (min-width: 1001px) and (max-width: 1280px) {
            @include gridItem(4);
        }
        @media (min-width: 769px) and (max-width: 1000px) {
            @include gridItem(3);
        }
        @media (max-width: 768px) {
            @include gridItem(2);
        }
        @media (max-width: 640px) {
            @include gridItem(2);
        }
        @media (max-width: 480px) {
            @include gridItem(1);
        }

    }

    .column-8 #{$g} li {
        @media (min-width: 1401px) {
            @include gridItem(4);
        }
        @media (min-width: 1281px) and (max-width: 1400px) {
            @include gridItem(3);
        }
        @media (min-width: 1001px) and (max-width: 1280px) {
            @include gridItem(2);
        }
        @media (min-width: 641px) and (max-width: 1000px) {
            @include gridItem(2);
        }
        @media (max-width: 640px) {
            @include gridItem(2);
        }
        @media (max-width: 480px) {
            @include gridItem(1);
        }
    }

    .column-6 #{$g} li {
        @media (min-width: 1401px) {
            @include gridItem(3);
        }
        @media (min-width: 1281px) and (max-width: 1400px) {
            @include gridItem(2);
        }
        @media (min-width: 1101px) and (max-width: 1280px) {
            @include gridItem(2);
        }
        @media (min-width: 641px) and (max-width: 1100px) {
            @include gridItem(1);
        }
        @media (max-width: 640px) {
            @include gridItem(2);
        }
        @media (max-width: 480px) {
            @include gridItem(1);
        }
    }

    //preview image
    .og-expander .og-fullimg {
        @media (min-width: 961px){
            @include detailsImage(402px);
        }
        @media (max-width: 960px) and (min-width: 769px){
            @include detailsImage(387px);
        }
        @media (max-width: 768px) {
            @include detailsImage(300px);
        }
        @media (max-width: 767px) {
            @include detailsImage(600px);
        }
        @media (max-width: 640px) {
            @include detailsImage(480px);
        }
        @media (max-width: 480px) {
            @include detailsImage(320px);
        }
        @media (max-width: 320px) {
            @include detailsImage(220px);
        }
    }

    .loadMore-wrapper {
        text-align: center;

        .og-additems {
            cursor: pointer;
            @include cta-btn(false);

            &.has-pill-button {
                @include pill-buttons('pill-button-container');
            }
        }
    }
}

@mixin profilegrid($ns) {
    #{$ns} {
        * {
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
        }
        h1 {
            color: #464646;
            font-size: 30px;
            line-height: 36px;
            font-weight: normal;
            margin-bottom: 15px;
            word-wrap: break-word;
        }
        .profile-grid-description {
            color: $gray-87;
            font-size: $ernie;
            line-height: 24px;
            margin-bottom: 0;
            word-wrap: break-word;
        }
    }
    @include grid($grid);
    @include overides($grid);
}

@include profilegrid($namespace)
