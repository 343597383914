@import 'styles/custom/includes';

.shop-component {
    .shop-logo {
        display: flex;
        justify-content: center;
        margin: 15px 0;
        img {
            max-width: 175px;
        }
    }

    .shop-image-wrapper {
        text-align: center;
    }

    .shop-description {
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: $gap;
        color: $gray-87;
        font-size: $ernie;

        @include breakpoint($below-lg) {
            padding: 0 $gap;
        }

    }

    .shop-img-link {
        position: relative;
        margin-bottom: $gap;
        display: inline-block;

        .shop-image {
            width: 100%;
            max-width: 350px;
            margin: 0 auto;
        }
    }

    .shop-links {
        max-width: 600px;
        margin: 0 auto;

        @include breakpoint($below-lg) {
            padding: 0 $gap;
        }

        .double-column {
            margin-bottom: $gap;

            ul {
                display: inline-block;
                width: 50%;

                &:first-of-type {
                    float: left;
                }

                &:last-of-type {
                    float: right;
                }
            }
        }

        .single-column {
            margin-left: 0;

            &:last-of-type {
                margin-bottom: $gap;
            }

            ul {
                display: block;
                width: 100%;
            }
        }

        ul {
            .has-pill-button .list-element-container {
                @include pill-buttons('pill-button-container');
            }

            .list-element-container {
                @include cta-btn();
                position: relative;

                margin-top: 15px;
                width: 100%;
                text-align: center;
                font-size: $ernie;

                &.has-link {
                    cursor: pointer;
                    padding: 0 !important;

                    .list-item-link {
                        width: 100%;
                        display: block;
                        height: 100%;
                        padding: 8px;
                    }
                }
                &:not(.has-link) {
                     padding: 8px !important;
                }
            }
        }
    }
}
