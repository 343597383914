.google-search-results {
    font-family: 'PBSSans', $sans-serif;

    input.gsc-input,
    .gs-snippet {
        font-size: $elmo;
    }
    input.gsc-input {
        background: none !important;
        box-shadow: none;
        margin: 0;
    }
    button.gsc-search-button{
        svg {
            vertical-align: middle;
        }
    }
    table.gsc-input {
        td {
            height: 35px;
        }
    }
}
