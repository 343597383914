.facebook-like-component {
    padding: $gap;

    .facebook-like-title {
        font-weight: $semibold;
        font-size: $elmo;
        margin-bottom: $gap-quarter;

        &.dark {
            color: #7f7f7f;
        }
    }
    .fb-like {

        &, span {
            &, iframe[style] {
                width: 100% !important;
            }
        }
    }
}
