@media (max-width: 320px) {
    .promo-container.box {
        padding: $gap;
    }
}

.promo-container {
    height: auto;

    .promo {
        position: relative;
        height: auto;
        background: $gray-0;

        &.clearfix {
            margin: 0;
        }

        .promo-text {
            padding: $gap $gap 35px;
            margin: 0;

            .channel,
            .title,
            .description {
                margin: 10px 0;
                line-height: $line-height;
            }

            .channel {
                @extend h5;
                color: $gray-80;
            }

            .title {
                font-size: $ernie;
                font-weight: $bold;
            }

            .description {
                font-size: $ernie;
                font-weight: $light;
            }
        }

        .read-more {
            // if there is no link, render CTA styles
            // this will result in a black border, black text
            &--no-link {
                @include cta-btn(false);
            }

            // this is the link element
            &__link {
                @include cta-btn(true);
            }

            &--has-link.has-pill-button {
               .read-more__link {
                    @include pill-buttons-link('pill-button-container');
                }
            }

            &--no-link.has-pill-button {
                @include pill-buttons('pill-button-container');

                &:hover {
                  border: 2px solid $basic-hover-blue;
                  border-radius: $pill-button-border-radius;
                  background-color: $basic-hover-blue;
                  color: $white;
                  cursor: pointer;
                }
            }
        }

        .promo-link {
            position: relative;
            .promo-play-icon {
                display: none;
                position: absolute;
                left: 10px;
                bottom: 10px;
            }

            // we need images to be constrained to their container
            img {
                max-width: 100%;
            }

            &:hover,
            &:focus {
                .promo-play-icon {
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }

    &.horizontal {
        background: 0 0;
        background-color: $gray-0;

        .promo {
            margin-left: 0;
            height: auto;

            @media only screen and (min-width: $sm) {
                display: flex;
                flex-wrap: nowrap;
                align-items: flex-start;
            }

            .promo-link {
                @media only screen and (min-width: $sm) {
                    width: 50%;
                }
                .promo-play-icon {
                    left: 10px;
                    bottom: 10px;
                }
            }
        }
    }

    &.vertical {
        .promo-link {
            display: inline-block;
            width: 100%;
        }
        .promo-text {
            width: 100%;
        }
    }
}
