.data-event-calendar {
    margin: 15px 5px;
    display: flex;
    flex-wrap: wrap;

    .ui-datepicker {
        width: 100%;
        padding: 1em 0;
        font-size: 13px;
        border: 1px solid $gray-30;

        .ui-datepicker-prev,
        .ui-datepicker-prev-hover {
            position: absolute;
            left: 0;
            top: 0;
            font-weight: $semibold;
            width: 1.8em;
            height: 1.8em;
            text-decoration: none;
            text-align: right;
            span {
                display: none;
            }
        }

        .ui-datepicker-next,
        .ui-datepicker-next-hover {
            position: absolute;
            right: 0;
            top: 0;
            font-weight: $semibold;
            width: 1.8em;
            height: 1.8em;
            text-decoration: none;
            text-align: left;
            span {
                display: none;
            }
        }

        .ui-datepicker-next,
        .ui-datepicker-next-hover {
            &:before {
                font-family: "Font Awesome 5 Free";
                font-weight: 600;
                content: '\f105';
            }
        }

        .ui-datepicker-prev,
        .ui-datepicker-prev-hover {
            &:before {
                font-family: "Font Awesome 5 Free";
                font-weight: 600;
                content: '\f104';
            }
        }
    }


    .ui-datepicker-calendar {
        cursor: default;
    }

    .ui-widget-content {
        background-color: transparent;
    }

    .ui-datepicker-header {
        border: none;
        background-color: transparent;
        color: $gray-100;
        font-weight: $semibold;

        a {
            color: $gray-100;
            cursor: pointer;
        }
    }

    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default {
        border: none;
        border-radius: 50%;
        width: 1.8em;
        height: 1.8em;
        background-color: transparent;
        font-weight: normal;
        color: $gray-71;
        font-weight: $normal;
        text-align: center;
        margin: 0 auto;
        cursor: default;
         a {
            text-decoration: none;
        }
    }




    .ui-state-active,
    .ui-widget-content .ui-state-active,
    .ui-widget-header .ui-state-active {
        border: none;
        background-color: transparent;
        font-weight: normal;
        color: $gray-71;
        font-weight: $normal;
        text-align: center;
        cursor: default;

        a {
            text-decoration: none;
        }
    }

    .ui-corner-all {
        border-radius: 0;
    }


    .calendar-title {
        text-transform: uppercase;
        color: $gray-71;
        font-weight: $semibold;
    }

    .ui-datepicker-calendar {

        td {
            text-align: center;
            height: 30px;

            a {
                display: inline-block;
                text-align: center;
            }
            &.highlight {

                a {
                    color: $gray-0;
                }
            }
        }
    }

    .ui-datepicker-inline {
        width: 100%;
        box-sizing: border-box;
    }

    .calendar {
        vertical-align: top;
        box-sizing: border-box;
        flex-grow: 1;
    }


    .events-container {
        white-space: normal;
        box-sizing: border-box;
        padding: 27px 10px 10px 10px;
        flex-grow: 1;

        .event-wrapper {
            clear: both;
            display: block;

            .bullet {
                margin-right: 10px;
                width: 10px;
                height: 10px;
                float: left;
                margin-top: 5px;
                border-radius: 50%;
            }
            .date,
            .title {
                font-size: 13px;
                margin: 0;
            }

            .title {
                font-weight: $semibold;
            }

            .link {
                font-size: 13px;
                padding-left: 5px;
                float: right;
            }
            hr {
                border-top: 1px solid  $gray-30;
                border-bottom: 1px solid  $gray-0;
            }
        }

        .pagination {
            text-align: center;
            display: none;

            ul {
                box-shadow: none;
                border: none;
                border-radius: 0;
            }

            li {
                * {
                    border: none;
                    font-weight: $semibold;
                    font-size: $abby;
                    border-right: 1px solid $gray-12;
                    background-color: transparent;
                }

                &.active {
                    .current {
                        color: $gray-100;
                        background-color: transparent;
                    }
                }
                a {
                    padding: 4px 6px;

                    &:hover,
                    &:focus {
                        background-color: transparent;
                    }
                }
                span {
                    padding: 4px 6px;
                }
                .next {
                    border-right: none !important;
                }
            }
        }
    }
}
